import { BankOutlined, BorderOuterOutlined, RetweetOutlined } from '@ant-design/icons';
import { Button, Typography, Table } from 'antd';
import React, { useCallback, useContext } from 'react';
import { z } from 'zod';

import * as API from '../../api';
import { DictFormActions, DictTableActions, DictTableStatus, Input, Select, Toolbar, ToolbarRight } from '../../components';
import { UserContext } from '../../contexts/UserContext';
import { useFillForm, useWindowSize } from '../../hooks';
import { AKTIF_PASIF, BhrStokOzetModel, DepoyeriModel, EVET_HAYIR, ROL } from '../../types';
import { moneyColumnRender, moneyFormat, numberFormat, yesNoRender } from '../../utils';
import { ColumnsType } from 'antd/es/table';
import get from 'lodash.get';

const { Text } = Typography;

const compNum = (keys: string[] | keyof BhrStokOzetModel) => (a: BhrStokOzetModel, b: BhrStokOzetModel) => {
  const aKey = Number(get(a, keys));
  const bKey = Number(get(b, keys));

  return aKey - bKey;
};

const compStr = (keys: string[] | keyof BhrStokOzetModel) => (a: BhrStokOzetModel, b: BhrStokOzetModel) => {
  const aKey = String(get(a, keys));
  const bKey = String(get(b, keys));

  return aKey.localeCompare(bKey);
};

const compDate = (keys: string[] | keyof BhrStokOzetModel) => (a: BhrStokOzetModel, b: BhrStokOzetModel) => {
  const aKey = new Date(get(a, keys));
  const bKey = new Date(get(b, keys));

  return aKey.getTime() - bKey.getTime();
};

export const Stok = () => {
  const { sm } = useWindowSize();
  const { firmaModel } = useContext(UserContext);

  const [list = [], loading, getList] = API.DASHBOARD.useFetchStok({ init: true, initBody: firmaModel });

  const data = list.map((item) => ({
    ...item,
    toplamAlis: item.stoktakiAdet * (item?.bhrUrunModel?.alisFiyat ?? 0),
    toplamSatis: item.stoktakiAdet * (item?.bhrUrunModel?.satisFiyat ?? 0),
  }));

  const columns: ColumnsType<BhrStokOzetModel> = [
    {
      title: 'Ürün',
      key: 'urunAdi',
      dataIndex: ['bhrUrunModel', 'urunAdi'],
      sorter: compStr(['bhrUrunModel', 'urunAdi']),
    },
    {
      title: 'Barkod',
      key: 'barkodNo',
      dataIndex: ['bhrUrunModel', 'barkodNo'],
      sorter: compStr(['bhrUrunModel', 'barkodNo']),
    },
    {
      title: 'Yoldaki',
      key: 'yoldaki',
      dataIndex: 'yoldakiAdet',
      align: 'right',
      render: numberFormat,
      sorter: compNum('yoldakiAdet'),
    },
    {
      title: 'Stoktaki',
      key: 'stoktaki',
      dataIndex: 'stoktakiAdet',
      align: 'right',
      render: numberFormat,
      sorter: compNum('stoktakiAdet'),
    },
    {
      title: 'Alış Fiyat',
      key: 'alisFiyat',
      dataIndex: ['bhrUrunModel', 'alisFiyat'],
      align: 'right',
      render: moneyColumnRender,
      sorter: compNum(['bhrUrunModel', 'alisFiyat']),
    },
    {
      title: 'Satış Fiyat',
      key: 'satisFiyat',
      dataIndex: ['bhrUrunModel', 'satisFiyat'],
      align: 'right',
      render: moneyColumnRender,
      sorter: compNum(['bhrUrunModel', 'satisFiyat']),
    },
    {
      title: 'Toplam Alış',
      key: 'toplamAlis',
      dataIndex: 'toplamAlis',
      align: 'right',
      render: moneyColumnRender,
      sorter: compNum('toplamAlis'),
    },
    {
      title: 'Toplam Satış',
      key: 'toplamSatis',
      dataIndex: 'toplamSatis',
      align: 'right',
      render: moneyColumnRender,
      sorter: compNum('toplamSatis'),
    },
  ];

  return (
    <div className="flex flex-col gap-2">
      <Toolbar>
        <ToolbarRight classname="sm:flex-none">
          <Button size="small" onClick={() => getList(firmaModel)} icon={<RetweetOutlined />}>
            Yenile
          </Button>
        </ToolbarRight>
      </Toolbar>
      <Table
        scroll={{ y: sm ? 'calc(100svh - 240px)' : 'calc(100svh - 190px)' }}
        pagination={false}
        size="small"
        bordered
        showSorterTooltip={false}
        columns={columns}
        dataSource={data}
        loading={loading}
        summary={(pageData) => {
          let totalYoldakiAdet = 0;
          let totalStoktakiAdet = 0;
          let totalToplamAlis = 0;
          let totalToplamSatis = 0;

          pageData.forEach(({ yoldakiAdet, stoktakiAdet, toplamAlis, toplamSatis }) => {
            totalYoldakiAdet += yoldakiAdet;
            totalStoktakiAdet += stoktakiAdet;
            totalToplamAlis += toplamAlis || 0;
            totalToplamSatis += toplamSatis || 0;
          });

          return (
            <Table.Summary fixed="bottom">
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  <Text strong>Toplam</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={1}>
                  <Text strong></Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={2}>
                  <Text strong>{totalYoldakiAdet}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={3}>
                  <Text strong>{totalStoktakiAdet}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={4}>
                  <Text strong></Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={5}>
                  <Text strong></Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={3}>
                  <Text strong>{moneyFormat(totalToplamAlis)}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={3}>
                  <Text strong>{moneyFormat(totalToplamSatis)}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />
    </div>
  );
};

Stok.path = 'stok';
Stok.title = 'Stok Özet';
Stok.group = 'supplier';
Stok.roles = [ROL.ADMIN];
Stok.icon = <BankOutlined />;
