import { useFetch } from '../hooks';
import { FaturaTipiModel, KategoriModel, UseFetchOptions } from '../types';
import { request } from '../utils';

export default {
  useFetch: (config: UseFetchOptions) => useFetch<KategoriModel[]>('/bhr/fatura-tipi/list-by-firma', { method: 'POST', ...config }),
  useFetchActive: (config: UseFetchOptions) => useFetch<KategoriModel[]>('/bhr/fatura-tipi/aktif-list-by-firma', { method: 'POST', ...config }),
  save: async (category: FaturaTipiModel) => request('/bhr/fatura-tipi/kayit', 'POST', category),
  update: async (category: FaturaTipiModel) => request('/bhr/fatura-tipi/guncelle', 'POST', category),
  activePassive: async (category: FaturaTipiModel) => request('/bhr/fatura-tipi/aktif-pasif', 'POST', category),
};
