import { Breadcrumb } from 'antd';
import React from 'react';
import { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type Props = {
  items: {
    key: string;
    icon: ReactNode;
    breadcrumbtitle: string;
  }[];
};

export const RouteBreadcrumb = ({ items = [] }: Props) => {
  const { pathname } = useLocation();
  const page = items.find((item) => item.key === pathname.replace('/', ''));
  const navigate = useNavigate();

  return (
    <div className="ml-12 sm:ml-4  mt-3">
      <Breadcrumb  >
        {page && (
          <Breadcrumb.Item onClick={() => navigate(0)}>
            {page.icon}
            <span>{page.breadcrumbtitle}</span>
          </Breadcrumb.Item>
        )}
      </Breadcrumb>
    </div>
  );
};
