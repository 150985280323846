import { DropboxOutlined } from '@ant-design/icons';
import { Card, Collapse, Table } from 'antd';
import React, { useCallback, useContext } from 'react';
import { z } from 'zod';

import * as API from '../../api';
import { DictFormActions, DictTableActions, DictTableStatus, Input } from '../../components';
import { UserContext } from '../../contexts/UserContext';
import { useFillForm, useWindowSize } from '../../hooks';
import { AKTIF_PASIF, KategoriModel, ROL } from '../../types';

const CategoryForm = z.object({
  faturaTipiAdi: z.string().min(1, 'Zorunludur'),
});

export const BillType = () => {
  const { sm } = useWindowSize();
  const { firmaModel } = useContext(UserContext);

  const [list = [], loading, getList] = API.BILLTYPE.useFetch({ init: true, initBody: firmaModel });
  const active = API.BILLTYPE.useFetchActive({});
  const { form, selected, clear, fill } = useFillForm(CategoryForm, { faturaTipiAdi: '' });

  const save = useCallback(async () => {
    const { success, data } = form.parse();

    if (success) {
      if (selected) {
        await API.BILLTYPE.update({
          ...data,
          faturaTipiId: selected.faturaTipiId,
          firmaModel,
        });
      } else {
        await API.BILLTYPE.save({
          ...data,
          firmaModel,
        });
      }

      clear();
      getList(firmaModel);
      active[2](firmaModel);
    } else {
      form.setAllTouched();
    }
  }, [form, selected]);

  const remove = useCallback(async (values: { faturaTipiId: any; aktifPasif: AKTIF_PASIF }) => {
    await API.BILLTYPE.activePassive({
      faturaTipiId: values.faturaTipiId,
      aktifPasif: values.aktifPasif === AKTIF_PASIF.AKTIF ? AKTIF_PASIF.PASIF : AKTIF_PASIF.AKTIF,
    });

    clear();
    getList(firmaModel);
  }, []);

  const columns = [
    {
      title: 'Fatura Tipi Adı',
      dataIndex: 'faturaTipiAdi',
      key: 'faturaTipiAdi',
    },
    {
      title: 'Durum',
      dataIndex: 'aktifPasif',
      key: 'aktifPasif',
      width: sm ? 100 : 70,
      render: (_: any, record: KategoriModel) => <DictTableStatus record={record} />,
    },
    {
      title: 'İşlemler',
      key: 'actions',
      width: sm ? 240 : 120,
      render: (_: any, record: KategoriModel) => <DictTableActions record={record} remove={remove} fill={fill} />,
    },
  ];

  return (
    <div className="flex flex-col gap-2">
      <Collapse accordion defaultActiveKey="1">
        <Collapse.Panel header={<span className="text-base font-semibold ">Yeni Fatura Tipi Tanımlama</span>} key="1">
          <div className="grid grid-cols-[100px_1fr] items-center gap-y-6 gap-x-4 sm:flex sm:flex-row">
            <label className="whitespace-nowrap">Fatura Tipi Adı :</label>
            <Input form={form} name="faturaTipiAdi" />

            <DictFormActions selected={selected} save={save} clear={clear} />
          </div>
        </Collapse.Panel>
      </Collapse>

      <Table
        scroll={{ y: sm ? 'calc(100svh - 240px)' : 'calc(100svh - 190px)' }}
        pagination={false}
        rowKey="kategoriId"
        size="small"
        bordered
        showSorterTooltip={false}
        columns={columns}
        dataSource={list}
        loading={loading}
      />
    </div>
  );
};

BillType.path = 'bill-type';
BillType.title = 'Fatura Tipi';
BillType.group = 'dicts';
BillType.roles = [ROL.ADMIN];
BillType.icon = <DropboxOutlined />;
