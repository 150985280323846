import { useFetch } from '../hooks';
import { MarkaModel, UseFetchOptions } from '../types';
import { request } from '../utils';

export default {
  useFetch: (config: UseFetchOptions) => useFetch<MarkaModel[]>('/bhr/marka/list-by-firma', { method: 'POST', ...config }),
  useFetchActive: (config: UseFetchOptions) => useFetch<MarkaModel[]>('/bhr/marka/aktif-list-by-firma', { method: 'POST', ...config }),
  save: async (brand: MarkaModel) => request('/bhr/marka/kayit', 'POST', brand),
  update: async (brand: MarkaModel) => request('/bhr/marka/guncelle', 'POST', brand),
  activePassive: async (brand: MarkaModel) => request('/bhr/marka/aktif-pasif', 'POST', brand),
};
