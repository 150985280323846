import { DropboxOutlined } from '@ant-design/icons';
import { Card, Collapse, Table } from 'antd';
import React, { useCallback, useContext } from 'react';
import { z } from 'zod';

import * as API from '../../api';
import { DictFormActions, DictTableActions, DictTableStatus, Input } from '../../components';
import { UserContext } from '../../contexts/UserContext';
import { useFillForm, useWindowSize } from '../../hooks';
import { AKTIF_PASIF, MarkaModel, ROL } from '../../types';

const BrandForm = z.object({
  markaAdi: z.string().min(1, 'Zorunludur'),
});

export const Brand = () => {
  const { sm } = useWindowSize();
  const { firmaModel } = useContext(UserContext);

  const [list = [], loading, getList] = API.BRAND.useFetch({ init: true, initBody: firmaModel });
  const active = API.BRAND.useFetchActive({});
  const { form, selected, clear, fill } = useFillForm(BrandForm, { markaAdi: '' });

  const save = useCallback(async () => {
    const { success, data } = form.parse();
    if (success) {
      if (selected) {
        await API.BRAND.update({
          ...data,
          markaId: selected.markaId,
          firmaModel,
        });
      } else {
        await API.BRAND.save({
          ...data,
          firmaModel,
        });
      }

      clear();
      getList(firmaModel);
      active[2](firmaModel);
    } else {
      form.setAllTouched();
    }
  }, [form, selected]);

  const remove = useCallback(async (values: { markaId: any; aktifPasif: AKTIF_PASIF }) => {
    await API.BRAND.activePassive({
      markaId: values.markaId,
      aktifPasif: values.aktifPasif === AKTIF_PASIF.AKTIF ? AKTIF_PASIF.PASIF : AKTIF_PASIF.AKTIF,
    });

    clear();
    getList(firmaModel);
  }, []);

  const columns = [
    {
      title: 'Marka Adı',
      dataIndex: 'markaAdi',
      key: 'markaAdi',
    },
    {
      title: 'Durum',
      dataIndex: 'aktifPasif',
      key: 'aktifPasif',
      width: sm ? 100 : 70,
      render: (_: any, record: MarkaModel) => <DictTableStatus record={record} />,
    },
    {
      title: 'İşlemler',
      key: 'actions',
      width: sm ? 240 : 120,
      render: (_: any, record: MarkaModel) => <DictTableActions record={record} remove={remove} fill={fill} />,
    },
  ];

  return (
    <div className="flex flex-col gap-2">
      <Collapse accordion defaultActiveKey="1">
        <Collapse.Panel header={<span className="text-base font-semibold ">Yeni Marka Tanımlama</span>} key="1">
          <div className="grid grid-cols-[100px_1fr] items-center gap-y-6 gap-x-4 sm:flex sm:flex-row">
            <label className="whitespace-nowrap">Marka Adı :</label>
            <Input form={form} name="markaAdi" />

            <DictFormActions selected={selected} save={save} clear={clear} />
          </div>
        </Collapse.Panel>
      </Collapse>

      <Table
        scroll={{ y: sm ? 'calc(100svh - 240px)' : 'calc(100svh - 190px)' }}
        pagination={false}
        rowKey="markaId"
        size="small"
        bordered
        showSorterTooltip={false}
        columns={columns}
        dataSource={list}
        loading={loading}
      />
    </div>
  );
};

Brand.path = 'brand';
Brand.title = 'Marka';
Brand.group = 'dicts';
Brand.roles = [ROL.ADMIN];
Brand.icon = <DropboxOutlined />;
