import { useFetch } from '../hooks';
import { DepoyeriModel, UseFetchOptions } from '../types';
import { request } from '../utils';

export default {
  useFetch: (config: UseFetchOptions) => useFetch<DepoyeriModel[]>('/bhr/depoyeri/list-by-firma', { method: 'POST', ...config }),
  useFetchActive: (config: UseFetchOptions) => useFetch<DepoyeriModel[]>('/bhr/depoyeri/aktif-list-by-firma', { method: 'POST', ...config }),
  useFetchActiveTed: (config: UseFetchOptions) => useFetch<DepoyeriModel[]>('/bhr/depoyeri/aktif-list-by-firma-for-tedarikci', { method: 'POST', ...config }),
  save: async (market: DepoyeriModel) => request('/bhr/depoyeri/kayit', 'POST', market),
  update: async (market: DepoyeriModel) => request('/bhr/depoyeri/guncelle', 'POST', market),
  activePassive: async (market: DepoyeriModel) => request('/bhr/depoyeri/aktif-pasif', 'POST', market),
};
