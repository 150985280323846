import * as XLSX from 'xlsx';
import { EVET_HAYIR, SiparisModel } from '../types';
import { saveAs } from 'file-saver';

const status = {
  SIP_YOLDA : 'YOLDA',
  SIP_DEPO : 'DEPODA',
  SIP_SATIS : 'SATILDI',
  SIP_IPTAL : 'İPTAL',
  SIP_ODEME_GELDI : 'ÖDEME GELDİ',
}

export function excelExportOrderList(orderList: SiparisModel[]) {
  const exportDataList = orderList.map(order => ({
    'Kayıt No': order.siparisId,
    'Tedarikçi': `${order.kullaniciModel?.kullaniciAdi} - ${order.kullaniciModel?.ad} ${order.kullaniciModel?.soyad}`,
    'Depoyeri': order.bhrDepoyeriModel?.depoyeriAdi,
    'Pazaryeri': order.bhrPazaryeriModel?.pazaryeriAdi,
    'Kategori': order.bhrKategoriModel?.kategoriAdi,
    'Marka': order.bhrMarkaModel?.markaAdi,
    'Ürün': order.bhrUrunModel?.urunAdi,
    'Sipariş No': order.siparisNo,
    'Sipariş Tarihi': order.siparisTarihi ? new Date(order.siparisTarihi) : '',
    'Alış Fiyat': order.alisFiyat,
    'Fatura Tipi': order.bhrFaturaTipiModel?.faturaTipiAdi,
    'Fatura Tarihi': order.faturaTarihi ? new Date(order.faturaTarihi) : '',
    'Faturası Var Mı': order.alisFaturasiOnaylandi === EVET_HAYIR.EVET  ? 'Evet' : 'Hayır',
    'Ödeme Tarihi': order.tedarikciOdenecekTarih ? new Date(order.tedarikciOdenecekTarih) : '',
    'İptal Tarihi': order.iptalTarihi ? new Date(order.iptalTarihi) : '',
    'Ödemesi Yapıldı Mı': order.odemesiYapildi === EVET_HAYIR.EVET ? 'Evet' : 'Hayır',
    'Açıklama': order.tedarikciAciklama,
    'Statü': status[order.siparisStatu || ''],
    'Kart': order.bhrKrediKartiModel?.kartAdi,
    'Sipariş Durumu': order.adminAciklama,
  }))

  const header = [
    'Kayıt No'
  ]

  const worksheet = XLSX.utils.json_to_sheet(exportDataList);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sipariş Listesi');

  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

  saveAsExcelFile(excelBuffer, 'Sipariş Listesi');
}

export function saveAsExcelFile(buffer: any, fileName: string) {
  let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  let EXCEL_EXTENSION = '.xlsx';
  const data: Blob = new Blob([buffer], {
    type: EXCEL_TYPE,
  });

  saveAs(data, fileName + EXCEL_EXTENSION);
}
