import { useFetch } from '../hooks';
import { UrunModel, UseFetchOptions } from '../types';
import { request } from '../utils';

export default {
  useFetch: (config: UseFetchOptions) => useFetch<UrunModel[]>('/bhr/urun/list-by-firma', { method: 'POST', ...config }),
  useFetchActive: (config: UseFetchOptions) => useFetch<UrunModel[]>('/bhr/urun/aktif-list-by-firma', { method: 'POST', ...config }),
  save: async (product: UrunModel) => request('/bhr/urun/kayit', 'POST', product),
  update: async (product: UrunModel) => request('/bhr/urun/guncelle', 'POST', product),
  activePassive: async (product: UrunModel) => request('/bhr/urun/aktif-pasif', 'POST', product),
};
