import { useFetch } from '../hooks';
import { BhrSepetModel, BhrSepetUrunModel, MarkaModel, UseFetchOptions } from '../types';
import { request } from '../utils';

export default {
  useFetch: (config: UseFetchOptions) => useFetch<BhrSepetModel[]>('/bhr/sepet/list-by-firma', { method: 'POST', ...config }),
  useFetchActive: (config: UseFetchOptions) => useFetch<BhrSepetModel[]>('/bhr/sepet/aktif-list-by-firma', { method: 'POST', ...config }),
  useFetchDetail: (config: UseFetchOptions) => useFetch<BhrSepetUrunModel[]>('/bhr/sepet/get-sepet-detay', { method: 'POST', ...config }),
  save: async (bhrSepetUrunModelList: BhrSepetUrunModel[]) => request('/bhr/sepet/kayit', 'POST', bhrSepetUrunModelList),
  activePassive: async (sepet: BhrSepetModel) => request('/bhr/sepet/aktif-pasif', 'POST', sepet),
};
