import { ClockCircleOutlined, ContainerOutlined, FieldTimeOutlined, HomeOutlined, SendOutlined, WalletOutlined } from '@ant-design/icons';
import { Card, Statistic, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useContext, useMemo } from 'react';
import { Cell, Legend, Pie, PieChart, Tooltip } from 'recharts';

import * as API from '../api';
import { UserContext } from '../contexts/UserContext';
import { AdminDashboardModel } from '../types';
import { moneyColumnRender, moneyFormat, numberFormat } from '../utils';
import { COLORS } from '../utils/chart';
import { useWindowSize } from '../hooks';

const formatter = new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY', maximumFractionDigits: 0, minimumFractionDigits: 0 });

export const AdminHome = () => {
  const { sm } = useWindowSize();

  const kullaniciModel = useContext(UserContext);
  const [data, loading, _getList] = API.DASHBOARD.useFetchAdmin({ init: true, initBody: kullaniciModel.firmaModel });
  
  const sortedEksikStokUrunModelList = useMemo(() => {
    if(data?.eksikStokUrunModelList) {
      return [...data.eksikStokUrunModelList].sort((a,b) => (b.eksikStok || 0) - (a.eksikStok || 0))
    }
    return []
  }, [data?.eksikStokUrunModelList])

  return (
    <div className="flex flex-col gap-2">
      <div className="col-span-2 flex flex-row gap-2">
        <Card className="h-24" bordered={false}>
          <Statistic title="Bu Ay Ödenecek" groupSeparator="." value={data?.buAyOdenecek || 0} valueStyle={{ color: '#3f8600' }} prefix={<WalletOutlined />} />
        </Card>
        <Card className="h-24" bordered={false}>
          <Statistic title="Bu Hafta Ödenecek" groupSeparator="." value={data?.buHaftaOdenecek || 0} valueStyle={{ color: '#3f8600' }} prefix={<WalletOutlined />} />
        </Card>
        <Card className="h-24" bordered={false}>
          <Statistic title="Bu Hafta Ödenecek" groupSeparator="." value={data?.odemesiBekleyen || 0} valueStyle={{ color: '#3f8600' }} prefix={<WalletOutlined />} />
        </Card>
        <Card className="h-24" bordered={false}>
          <Statistic title="Satılmış Adet" groupSeparator="." value={data?.satilmisAdet || 0} valueStyle={{ color: '#3f8600' }} prefix={<SendOutlined />} />
        </Card>
        <Card className="h-24" bordered={false}>
          <Statistic title="Stoktaki Adet" groupSeparator="." value={data?.stoktakiAdet || 0} valueStyle={{ color: '#3f8600' }} prefix={<HomeOutlined />} />
        </Card>
      </div>
      <div className="grid grid-cols-2 gap-2">
        {/*<Card>
          <h3 className="w-full text-center mt-0">Tedarikçi Bazında Adet Listesi</h3>
          <Table dataSource={data?.tedarikciBazindaAdetList} scroll={{ y: sm ? 'calc(100svh - 300px)' : 'calc(100vh - 300px)' }} pagination={false} bordered>
            <Table.Column align="center" title="Tedarikçi" dataIndex="key" key="key" />
            <Table.Column align="center" title="Adet" dataIndex="value" key="value" render={numberFormat} />
          </Table>
        </Card>
        <Card>
          <h3 className="w-full text-center mt-0">Tedarikçi Bazında Ciro Listesi</h3>

          <Table dataSource={data?.tedarikciBazindaCiroList} scroll={{ y: sm ? 'calc(100svh - 300px)' : 'calc(100vh - 300px)' }} pagination={false} bordered>
            <Table.Column align="center" title="Tedarikçi" dataIndex="key" key="key" />
            <Table.Column align="center" title="Ciro" dataIndex="value" key="value" render={numberFormat} />
          </Table>
        </Card>*/}
        <Card className="col-span-2">
          <h3 className="w-full text-center mt-0">Eksik Stok Listesi</h3>

          <Table dataSource={sortedEksikStokUrunModelList} scroll={{ y: sm ? 'calc(100svh - 300px)' : 'calc(100vh - 300px)' }} pagination={false} bordered>
            <Table.Column align="left" title="Ürün Adı" dataIndex="urunAdi" key="urunAdi" />
            <Table.Column align="center" title="Alış Fiyatı" dataIndex="alisFiyat" width={150}  key="alisFiyat" render={numberFormat} />
            <Table.Column align="center" title="Ürün Grup" dataIndex="urunGrup" width={160}  key="urunGrup" />
            <Table.Column align="center" title="Hedef" dataIndex="hedef" key="hedef" width={120}  render={numberFormat} />
            <Table.Column align="center" title="Eksik Stok" dataIndex="eksikStok" key="eksikStok" width={120} render={numberFormat} />
          </Table>
        </Card>
      </div>
    </div>
  );
};
