import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { OrderDetailButton } from '../components/OrderDetailButton';
import { EVET_HAYIR, SiparisModel, SIPARIS_STATU } from '../types';
import { dateFormat, dateFormatTime, moneyColumnRender, monthFormat, yesNoRender } from './column';
import { CargoUrl } from '../components/CargoUrl';
import { Button, Tag } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import get from 'lodash.get';

const compNum = (keys: string[] | keyof SiparisModel) => (a: SiparisModel, b: SiparisModel) => {
  const aKey = Number(get(a, keys));
  const bKey = Number(get(b, keys));

  return aKey - bKey;
};

const compStr = (keys: string[] | keyof SiparisModel) => (a: SiparisModel, b: SiparisModel) => {
  const aKey = String(get(a, keys));
  const bKey = String(get(b, keys));

  return aKey.localeCompare(bKey);
};

const compDate = (keys: string[] | keyof SiparisModel) => (a: SiparisModel, b: SiparisModel) => {
  const aKey = new Date(get(a, keys));
  const bKey = new Date(get(b, keys));

  return aKey.getTime() - bKey.getTime();
};

const status = {
  SIP_YOLDA : 'YOLDA',
  SIP_DEPO : 'DEPODA',
  SIP_SATIS : 'SATILDI',
  SIP_IPTAL : 'İPTAL',
  SIP_ODEME_GELDI : 'ÖDEME GELDİ',
}

export const createOrderColumns = (faturaBekleyenler = false, onayBekleyenler = false, isAdmin: boolean): ColumnsType<SiparisModel> => {
  const main = !(faturaBekleyenler && onayBekleyenler);
  const columns: ColumnsType<SiparisModel> = [
    {
      title: 'Kayıt No',
      key: 'siparisId',
      dataIndex: 'siparisId',
      width: 60,
      align: 'center',
      sorter: compNum('siparisId'),
    },
    onayBekleyenler
      ? {
          title: 'Fatura Kayıt No',
          key: ' alisFaturasiModelDokumanId',
          dataIndex: ['alisFaturasiModel', 'dokumanId'],
          width: 100,
          align: 'center',
          sorter: compNum('satisFiyat'),
          responsive: ['md'],
        }
      : {},
    {
      title: 'Depoyeri',
      key: 'bhrDepoyeriModel',
      dataIndex: ['bhrDepoyeriModel', 'depoyeriAdi'],
      sorter: compStr(['bhrDepoyeriModel', 'depoyeriAdi']),
      responsive: ['md'],
    },
    {
      title: 'Ürün',
      key: 'bhrUrunModel',
      dataIndex: ['bhrUrunModel', 'urunAdi'],
      sorter: compStr(['bhrUrunModel', 'urunAdi']),
      responsive: ['md'],
    },

    {
      title: 'Barkod',
      key: 'barkodNo',
      dataIndex: ['bhrUrunModel', 'barkodNo'],
      sorter: compStr(['bhrUrunModel', 'barkodNo']),
      responsive: ['md'],
    },
    {
      title: 'Sipariş No',
      key: 'siparisNo',
      dataIndex: 'siparisNo',
      sorter: compStr('siparisNo'),
      render: (_, order) =>  <a target="_blank" rel="noopener noreferrer" href={`https://www.karaca.com/account/track?email=${order.tedarikciAciklama}&orderid=${order.siparisNo}`}>
      {order.siparisNo}
    </a>
    },
    {
      title: 'Sipariş Tarihi',
      key: 'siparisTarihi',
      dataIndex: 'siparisTarihi',
      render: dateFormat,
      align: 'center',
      width: 90,
      sorter: compDate('siparisTarihi'),
    },
    {
      title: 'Satış Tarihi',
      key: 'satisTarihi',
      dataIndex: 'satisTarihi',
      render: dateFormatTime,
      align: 'center',
      width: 90,
      sorter: compDate('satisTarihi'),
      responsive: ['md'],
    },
    {
      title: 'Alış Fiyat',
      key: 'alisFiyat',
      dataIndex: 'alisFiyat',
      align: 'right',
      render: moneyColumnRender,
      sorter: compNum('alisFiyat'),
    },
    isAdmin
      ? {
          title: 'Satış Fiyat',
          key: 'satisFiyat',
          dataIndex: 'satisFiyat',
          align: 'right',
          render: moneyColumnRender,
          sorter: compNum('satisFiyat'),
          responsive: ['md'],
        }
      : {},
    {
      title: 'Ödeme Tarihi',
      key: 'tedarikciOdenecekTarih',
      dataIndex: 'tedarikciOdenecekTarih',
      render: dateFormat,
      align: 'center',
      width: 90,
      sorter: compDate('tedarikciOdenecekTarih'),
      responsive: ['md'],
    },
    {
      title: 'İptal Tarihi',
      key: 'iptalTarihi',
      dataIndex: 'iptalTarihi',
      render: dateFormat,
      align: 'center',
      width: 90,
      sorter: compDate('iptalTarihi'),
      responsive: ['md'],
    },
    {
      title: 'Hesap',
      key: 'tedarikciAciklama',
      dataIndex: 'tedarikciAciklama',
      responsive: ['md'],
    },
    {
      title: 'Statü',
      key: 'siparisStatu',
      dataIndex: 'siparisStatu',
      render: (text) => {
        return status[text]
      } ,
      sorter: compStr('siparisStatu'),
      responsive: ['md'],
    },
    {
      title: 'Kart',
      key: 'bhrKrediKartiModel',
      dataIndex: ['bhrKrediKartiModel', 'kartAdi'],
      responsive: ['md'],
    },
    {
      title: 'Sipariş Durumu',
      key: 'adminAciklama',
      dataIndex: 'adminAciklama',
      sorter: compStr('adminAciklama'),
      responsive: ['md'],
    },
  ];

  if (isAdmin) {
    columns.splice(1, 0, {
      title: 'Tedarikçi',
      key: 'tedarikci',
      width: 140,
      responsive: ['md'],
      render: (_, record) => `${record.kullaniciModel?.kullaniciAdi} - ${record.kullaniciModel?.ad} ${record.kullaniciModel?.soyad}`,
      sorter: compStr(['kullaniciModel', 'kullaniciAdi']),
    });
  }

  return columns.filter(Boolean).filter((item) => item.title);
};

export const createOrderColumnsPaymentStep1 = (): ColumnsType<SiparisModel> => {
  return [
    {
      title: 'Kayıt No',
      key: 'siparisId',
      dataIndex: 'siparisId',
      width: 80,
      align: 'center',
    },
    {
      title: 'Pazaryeri',
      key: 'pazaryeriModel',
      dataIndex: ['pazaryeriModel', 'pazaryeriAdi'],
    },
    {
      title: 'Kategori',
      key: 'kategoriModel',
      dataIndex: ['kategoriModel', 'kategoriAdi'],
    },
    {
      title: 'Ürün',
      key: 'urunModel',
      dataIndex: ['urunModel', 'urunAdi'],
    },
    {
      title: 'Sipariş No',
      key: 'siparisNo',
      dataIndex: 'siparisNo',
    },
    {
      title: 'Sipariş Tarihi',
      key: 'siparisTarihi',
      dataIndex: 'siparisTarihi',
      render: dateFormat,
    },
    {
      title: 'Alış Fiyat',
      key: 'alisFiyat',
      dataIndex: 'alisFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Ödenecek Fiyat',
      key: 'tedarikciOdenecekFiyat',
      dataIndex: 'tedarikciOdenecekFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Ödenecek Tarih',
      key: 'tedarikciOdenecekTarih',
      dataIndex: 'tedarikciOdenecekTarih',
      render: dateFormat,
    },
    {
      title: 'Açıklama',
      key: 'tedarikciAciklama',
      dataIndex: 'tedarikciAciklama',
    },
  ];
};

export const createOrderColumnsPaymentStep2 = (): ColumnsType<SiparisModel> => {
  return [
    {
      title: 'Kayıt No',
      key: 'siparisId',
      dataIndex: 'siparisId',
      width: 80,
      align: 'center',
    },
    {
      title: 'Pazaryeri',
      key: 'pazaryeriModel',
      dataIndex: ['pazaryeriModel', 'pazaryeriAdi'],
    },
    {
      title: 'Kategori',
      key: 'kategoriModel',
      dataIndex: ['kategoriModel', 'kategoriAdi'],
    },
    {
      title: 'Ürün',
      key: 'urunModel',
      dataIndex: ['urunModel', 'urunAdi'],
    },
    {
      title: 'Sipariş No',
      key: 'siparisNo',
      dataIndex: 'siparisNo',
    },
    {
      title: 'Sipariş Tarihi',
      key: 'siparisTarihi',
      dataIndex: 'siparisTarihi',
      render: dateFormat,
    },
    {
      title: 'Alış Fiyat',
      key: 'alisFiyat',
      dataIndex: 'alisFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Ödenecek Fiyat',
      key: 'tedarikciOdenecekFiyat',
      dataIndex: 'tedarikciOdenecekFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Ödenecek Tarih',
      key: 'tedarikciOdenecekTarih',
      dataIndex: 'tedarikciOdenecekTarih',
      render: dateFormat,
    },
    {
      title: 'Ödenenen Fiyat',
      key: 'tedarikciOdenenFiyat',
      dataIndex: 'tedarikciOdenenFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Ödenenen Tarih',
      key: 'tedarikciOdenenTarih',
      dataIndex: 'tedarikciOdenenTarih',
      render: dateFormat,
    },
    {
      title: 'Açıklama',
      key: 'tedarikciAciklama',
      dataIndex: 'tedarikciAciklama',
    },
  ];
};

export const createOrderColumnsSalesStep1 = (onUpdateDone: CallableFunction): ColumnsType<SiparisModel> => {
  return [
    {
      title: 'Tedarikçi',
      key: 'tedarikci',
      width: 220,
      render: (_, record) => `${record.kullaniciModel?.kullaniciAdi} - ${record.kullaniciModel?.ad} ${record.kullaniciModel?.soyad}`,
    },
    {
      title: 'Kayıt No',
      key: 'siparisId',
      dataIndex: 'siparisId',
      width: 80,
      align: 'center',
      render: (_, record) => <OrderDetailButton order={record} onUpdateDone={onUpdateDone} />,
    },
    {
      title: 'Pazaryeri',
      key: 'pazaryeriModel',
      dataIndex: ['pazaryeriModel', 'pazaryeriAdi'],
    },
    {
      title: 'Kategori',
      key: 'kategoriModel',
      dataIndex: ['kategoriModel', 'kategoriAdi'],
    },
    {
      title: 'Ürün',
      key: 'urunModel',
      dataIndex: ['urunModel', 'urunAdi'],
    },
    {
      title: 'Barkod',
      key: 'barkod',
      dataIndex: ['urunModel', 'barkodNo'],
    },
    {
      title: 'Sipariş No',
      key: 'siparisNo',
      dataIndex: 'siparisNo',
    },
    {
      title: 'Kargo Firması',
      key: 'alisKargoFirmasiModel',
      dataIndex: ['alisKargoFirmasiModel', 'kargoFirmasiAdi'],
    },
    {
      title: 'Kargo No',
      key: 'kargoNo',
      dataIndex: 'kargoNo',
      width: 150,
      render: (_, order) => <CargoUrl cargoId={order?.alisKargoFirmasiModel?.kargoFirmasiId} cargoNo={order?.kargoNo} />,
    },
    {
      title: 'Alış Fiyat',
      key: 'alisFiyat',
      dataIndex: 'alisFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Fatura Onay',
      key: 'alisFaturasiOnaylandi',
      dataIndex: 'alisFaturasiOnaylandi',
      align: 'center',
      render: yesNoRender,
    },
    {
      title: 'Açıklama',
      key: 'tedarikciAciklama',
      dataIndex: 'tedarikciAciklama',
    },
  ];
};

export const createOrderColumnsSalesStep2 = (onUpdateDone: CallableFunction): ColumnsType<SiparisModel> => {
  return [
    {
      title: 'Kayıt No',
      key: 'siparisId',
      dataIndex: 'siparisId',
      width: 80,
      align: 'center',
      render: (_, record) => <OrderDetailButton order={record} onUpdateDone={onUpdateDone} />,
    },
    {
      title: 'Depoyeri',
      key: 'depoyeriAdi',
      dataIndex: ['depoyeriModel', 'depoyeriAdi'],
    },
    {
      title: 'Kategori',
      key: 'kategoriModel',
      dataIndex: ['kategoriModel', 'kategoriAdi'],
    },
    {
      title: 'Ürün',
      key: 'urunModel',
      dataIndex: ['urunModel', 'urunAdi'],
    },
    {
      title: 'Barkod',
      key: 'barkod',
      dataIndex: ['urunModel', 'barkodNo'],
    },
    {
      title: 'Seri No / Imei No',
      key: 'seriNo',
      dataIndex: 'seriNo',
    },
    {
      title: 'Alış Fiyat',
      key: 'alisFiyat',
      dataIndex: 'alisFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Ödenecek Fiyat',
      key: 'tedarikciOdenecekFiyat',
      dataIndex: 'tedarikciOdenecekFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Ödenenen Fiyat',
      key: 'tedarikciOdenenFiyat',
      dataIndex: 'tedarikciOdenenFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Fatura Onay',
      key: 'alisFaturasiOnaylandi',
      dataIndex: 'alisFaturasiOnaylandi',
      align: 'center',
      render: yesNoRender,
    },
    {
      title: 'Depo Giriş Tarihi',
      key: 'siparisDepoGirisTarihi',
      dataIndex: 'siparisDepoGirisTarihi',
      render: dateFormat,
    },
  ];
};

export const createOrderColumnsSalesStep3 = (onUpdateDone: CallableFunction): ColumnsType<SiparisModel> => {
  return [
    {
      title: 'Kayıt No',
      key: 'siparisId',
      dataIndex: 'siparisId',
      width: 80,
      align: 'center',
      render: (_, record) => <OrderDetailButton order={record} onUpdateDone={onUpdateDone} />,
    },
    {
      title: 'Depoyeri',
      key: 'depoyeriModel',
      dataIndex: ['depoyeriModel', 'depoyeriAdi'],
    },
    {
      title: 'Satış Pazaryeri',
      key: 'pazaryeriModel',
      dataIndex: ['satisPazaryeriModel', 'pazaryeriAdi'],
    },
    {
      title: 'Kategori',
      key: 'kategoriModel',
      dataIndex: ['kategoriModel', 'kategoriAdi'],
    },
    {
      title: 'Ürün',
      key: 'urunModel',
      dataIndex: ['urunModel', 'urunAdi'],
    },
    {
      title: 'Barkod',
      key: 'barkod',
      dataIndex: ['urunModel', 'barkodNo'],
    },
    {
      title: 'Seri No / Imei No',
      key: 'seriNo',
      dataIndex: 'seriNo',
    },
    {
      title: 'Alış Fiyat',
      key: 'alisFiyat',
      dataIndex: 'alisFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Ödenecek Fiyat',
      key: 'tedarikciOdenecekFiyat',
      dataIndex: 'tedarikciOdenecekFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Ödenenen Fiyat',
      key: 'tedarikciOdenenFiyat',
      dataIndex: 'tedarikciOdenenenFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Depo Giriş Tarihi',
      key: 'siparisDepoGirisTarihi',
      dataIndex: 'siparisDepoGirisTarihi',
      render: dateFormat,
    },
  ];
};

export const createOrderColumnsSalesStep4 = (onUpdateDone: CallableFunction): ColumnsType<SiparisModel> => {
  return [
    {
      title: 'Kayıt No',
      key: 'siparisId',
      dataIndex: 'siparisId',
      width: 80,
      align: 'center',
      render: (_, record) => <OrderDetailButton order={record} onUpdateDone={onUpdateDone} />,
    },
    {
      title: 'Depoyeri',
      key: 'depoyeriModel',
      dataIndex: ['depoyeriModel', 'depoyeriAdi'],
    },
    {
      title: 'Satış Pazaryeri',
      key: 'pazaryeriModel',
      dataIndex: ['satisPazaryeriModel', 'pazaryeriAdi'],
    },
    {
      title: 'Kategori',
      key: 'kategoriModel',
      dataIndex: ['kategoriModel', 'kategoriAdi'],
    },
    {
      title: 'Ürün',
      key: 'urunModel',
      dataIndex: ['urunModel', 'urunAdi'],
    },
    {
      title: 'Seri No / Imei No',
      key: 'seriNo',
      dataIndex: 'seriNo',
    },
    {
      title: 'Alış Fiyat',
      key: 'alisFiyat',
      dataIndex: 'alisFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Satış Fiyat',
      key: 'satisFiyat',
      dataIndex: 'satisFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Satış Tarihi',
      key: 'satisTarihi',
      dataIndex: 'satisTarihi',
      align: 'center',
      render: dateFormat,
    },
  ];
};

export const createOrderColumnsSalesStep5 = (onUpdateDone: CallableFunction): ColumnsType<SiparisModel> => {
  return [
    {
      title: 'Kayıt No',
      key: 'siparisId',
      dataIndex: 'siparisId',
      width: 80,
      align: 'center',
      render: (_, record) => <OrderDetailButton order={record} onUpdateDone={onUpdateDone} />,
    },
    {
      title: 'Depoyeri',
      key: 'depoyeriModel',
      dataIndex: ['depoyeriModel', 'depoyeriAdi'],
    },
    {
      title: 'Satış Pazaryeri',
      key: 'pazaryeriModel',
      dataIndex: ['satisPazaryeriModel', 'pazaryeriAdi'],
    },
    {
      title: 'Kategori',
      key: 'kategoriModel',
      dataIndex: ['kategoriModel', 'kategoriAdi'],
    },
    {
      title: 'Ürün',
      key: 'urunModel',
      dataIndex: ['urunModel', 'urunAdi'],
    },
    {
      title: 'Seri No / Imei No',
      key: 'seriNo',
      dataIndex: 'seriNo',
    },
    {
      title: 'Alış Fiyat',
      key: 'alisFiyat',
      dataIndex: 'alisFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Satış Fiyat',
      key: 'satisFiyat',
      dataIndex: 'satisFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Satış Tarihi',
      key: 'satisTarihi',
      dataIndex: 'satisTarihi',
      align: 'center',
      render: dateFormat,
    },
    {
      title: 'Kargo Tarihi',
      key: 'satisKargoTarihi',
      dataIndex: 'satisKargoTarihi',
      align: 'center',
      render: dateFormat,
    },
  ];
};

export const createOrderColumnsUserPayment = (onUpdateDone: CallableFunction): ColumnsType<SiparisModel> => {
  return [
    {
      title: 'Kayıt No',
      key: 'siparisId',
      dataIndex: 'siparisId',
      width: 80,
      align: 'center',
      render: (_, record) => <OrderDetailButton order={record} onUpdateDone={onUpdateDone} />,
    },
    {
      title: 'Tedarikçi',
      key: 'tedarikci',
      width: 220,
      render: (_, record) => `${record.kullaniciModel?.kullaniciAdi} - ${record.kullaniciModel?.ad} ${record.kullaniciModel?.soyad}`,
    },
    {
      title: 'Pazaryeri',
      key: 'pazaryeriModel',
      dataIndex: ['pazaryeriModel', 'pazaryeriAdi'],
    },
    {
      title: 'Kategori',
      key: 'kategoriModel',
      dataIndex: ['kategoriModel', 'kategoriAdi'],
    },
    {
      title: 'Ürün',
      key: 'urunModel',
      dataIndex: ['urunModel', 'urunAdi'],
    },
    {
      title: 'Sipariş No',
      key: 'siparisNo',
      dataIndex: 'siparisNo',
    },
    {
      title: 'Sipariş Tarihi',
      key: 'siparisTarihi',
      dataIndex: 'siparisTarihi',
      render: dateFormat,
    },
    {
      title: 'Alış Fiyat',
      key: 'alisFiyat',
      dataIndex: 'alisFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Ödenecek Fiyat',
      key: 'tedarikciOdenecekFiyat',
      dataIndex: 'tedarikciOdenecekFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Ödenecek Tarih',
      key: 'tedarikciOdenecekTarih',
      dataIndex: 'tedarikciOdenecekTarih',
      render: dateFormat,
    },
    {
      title: 'Açıklama',
      key: 'tedarikciAciklama',
      dataIndex: 'tedarikciAciklama',
    },
  ];
};

export const createOrderColumnsBillStep1 = (): ColumnsType<SiparisModel> => {
  return [
    {
      title: 'Kayıt No',
      key: 'siparisId',
      dataIndex: 'siparisId',
      width: 80,
      align: 'center',
    },
    {
      title: 'Pazaryeri',
      key: 'pazaryeriModel',
      dataIndex: ['pazaryeriModel', 'pazaryeriAdi'],
    },
    {
      title: 'Kategori',
      key: 'kategoriModel',
      dataIndex: ['kategoriModel', 'kategoriAdi'],
    },
    {
      title: 'Ürün',
      key: 'urunModel',
      dataIndex: ['urunModel', 'urunAdi'],
    },
    {
      title: 'Sipariş No',
      key: 'siparisNo',
      dataIndex: 'siparisNo',
    },
    {
      title: 'Kargo Firması',
      key: 'alisKargoFirmasiModel',
      dataIndex: ['alisKargoFirmasiModel', 'kargoFirmasiAdi'],
    },
    {
      title: 'Kargo No',
      key: 'kargoNo',
      dataIndex: 'kargoNo',
      width: 150,
      render: (_, order) => <CargoUrl cargoId={order?.alisKargoFirmasiModel?.kargoFirmasiId} cargoNo={order?.kargoNo} />,
    },
    {
      title: 'Alış Fiyat',
      key: 'alisFiyat',
      dataIndex: 'alisFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Açıklama',
      key: 'tedarikciAciklama',
      dataIndex: 'tedarikciAciklama',
    },
    {
      title: 'Durum',
      key: 'durum',
      dataIndex: 'durum',
      align: 'center',
      render: (_, order) => <span> {order.alisFaturasiModel && order.alisFaturasiOnaylandi === EVET_HAYIR.HAYIR ? 'Onay Bekliyor' : ''} </span>,
    },
  ];
};

export const createOrderColumnsBillStep4 = (onDownload: CallableFunction): ColumnsType<SiparisModel> => {
  return [
    {
      title: 'Kayıt No',
      key: 'siparisId',
      dataIndex: 'siparisId',
      width: 80,
      align: 'center',
    },
    {
      title: 'Tedarikçi',
      key: 'tedarikci',
      width: 220,
      render: (_, record) => `${record.kullaniciModel?.kullaniciAdi} - ${record.kullaniciModel?.ad} ${record.kullaniciModel?.soyad}`,
    },
    {
      title: 'Ürün',
      key: 'urunModel',
      dataIndex: ['urunModel', 'urunAdi'],
    },
    {
      title: 'Alış Fiyat',
      key: 'alisFiyat',
      dataIndex: 'alisFiyat',
      align: 'right',
      render: moneyColumnRender,
    },
    {
      title: 'Sipariş Tarihi',
      key: 'siparisTarihi',
      dataIndex: 'siparisTarihi',
      align: 'center',
      render: dateFormat,
    },
    {
      title: 'Fatura',
      key: 'fatura',
      dataIndex: 'fatura',
      align: 'center',
      render: (_, order) => (
        <Button className="w-full" onClick={() => onDownload(order)} icon={<CloudDownloadOutlined />}>
          İndir
        </Button>
      ),
    },
  ];
};
