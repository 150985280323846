import { Button, Collapse, Descriptions, Drawer } from 'antd';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { z } from 'zod';

import { ArrowUpOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import * as API from '../api';
import { UserContext } from '../contexts/UserContext';
import { useFillForm } from '../hooks';
import { DokumanModel, ROL, SiparisModel } from '../types';
import { dateFormat, moneyFormat } from '../utils';
import { DatePicker } from './DatePicker';
import { Input } from './Input';
import { InputNumber } from './InputNumber';
import { Select } from './Select';
import { CargoUrl } from './CargoUrl';

const STATU_MAP = {
  SIP_OLUSTU: 'Oluşturuldu',
  SIP_ONAY: 'Onaylandı',
  SIP_KARGO: 'Kargoda',
  SIP_DEPO: 'Depoda',
  SIP_ILAN: 'İlanda',
  SIP_SATIS: 'Satıldı',
  SATIS_KARGO: 'Müşteriye Kargolandı',
};

const OrderForm = z.object({
  pazaryeriId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  kategoriId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  urunId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  depoyeriId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  alisFiyat: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }).gt(0, "Fiyat 0'dan büyük olmalı."),
  siparisNo: z.string().min(1, 'Zorunludur'),
  siparisTarihi: z.date({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  tedarikciAciklama: z.string().optional(),
  kargoFirmasiId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  kargoNo: z.string().optional(),
  siparisKargoTarihi: z.date({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
});

export const OrderDetailButton = ({ order, onUpdateDone }: { order: SiparisModel; onUpdateDone: CallableFunction }) => {
  const kullaniciModel = useContext(UserContext);
  const [open, setOpen] = useState(false);

  const [markets = []] = API.MARKETS.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [categories = []] = API.CATEGORY.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [products = []] = API.PRODUCT.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [warehouses = []] = API.WAREHOUSES.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });

  const { form, fill } = useFillForm(OrderForm, {
    pazaryeriId: undefined,
    kategoriId: undefined,
    urunId: undefined,
    depoyeriId: undefined,
    alisFiyat: undefined,
    siparisNo: '',
    siparisTarihi: new Date(),
    tedarikciAciklama: '',
    kargoFirmasiId: undefined,
    kargoNo: '',
    siparisKargoTarihi: new Date(),
  });

  const productByCategory = useMemo(() => {
    return products.filter((product) => product.kategoriModel?.kategoriId === form.value.kategoriId);
  }, [form.value.kategoriId]);

  const update = useCallback(async () => {
    const { success, data } = form.parse();
    if (success) {
      const formedData = {
        ...order,
        pazaryeriModel: {
          pazaryeriId: data.pazaryeriId,
        },
        kategoriModel: {
          kategoriId: data.kategoriId,
        },
        urunModel: {
          urunId: data.urunId,
        },
        depoyeriModel: {
          depoyeriId: data.depoyeriId,
        },
        alisFiyat: data.alisFiyat,
        siparisNo: data.siparisNo,
        siparisTarihi: data.siparisTarihi.toISOString(),
        tedarikciAciklama: data.tedarikciAciklama,
        firmaModel: kullaniciModel.firmaModel,
        alisKargoFirmasiModel: {
          kargoFirmasiId: data.kargoFirmasiId,
        },
        kargoNo: data.kargoNo,
        siparisKargoTarihi: data.siparisKargoTarihi.toISOString(),
      };
      await API.ORDERS.update(formedData);

      onUpdateDone();
      form.reset();
      setOpen(false);
    } else {
      form.setAllTouched();
    }
  }, [form]);

  const downloadFile = useCallback(async (file?: DokumanModel) => {
    if (file) {
      API.FILES.download(file);
    }
  }, []);

  useEffect(() => {
    form.reset();
    fill({
      pazaryeriId: order.pazaryeriModel?.pazaryeriId,
      kategoriId: order.kategoriModel?.kategoriId,
      urunId: order.urunModel?.urunId,
      depoyeriId: order.depoyeriModel?.depoyeriId,
      alisFiyat: order.alisFiyat,
      siparisNo: order.siparisNo,
      siparisTarihi: order.siparisTarihi ? new Date(order.siparisTarihi) : new Date(),
      tedarikciAciklama: order.tedarikciAciklama || '',
      kargoFirmasiId: order.alisKargoFirmasiModel?.kargoFirmasiId,
      kargoNo: order.kargoNo,
      siparisKargoTarihi: order.siparisKargoTarihi ? new Date(order.siparisKargoTarihi) : new Date(),
    });
  }, [open, order]);

  return (
    <>
      <Button type="primary" onClick={() => setOpen(true)}>
        {order.siparisId}
      </Button>
      <Drawer title="Detay" placement="right" size="large" onClose={() => setOpen(false)} open={open}>
        <Descriptions size="small" layout="horizontal" column={2} labelStyle={{ fontWeight: 'bold' }} bordered>
          <Descriptions.Item label="Kayıt No">{order?.siparisId}</Descriptions.Item>
          <Descriptions.Item label="Durum">{STATU_MAP[order?.siparisStatu || '']}</Descriptions.Item>
          <Descriptions.Item label="Depoyeri">{order?.depoyeriModel?.depoyeriAdi}</Descriptions.Item>
          <Descriptions.Item label="Kategori">{order?.kategoriModel?.kategoriAdi}</Descriptions.Item>
          <Descriptions.Item label="Ürün">{order?.urunModel?.urunAdi}</Descriptions.Item>
          <Descriptions.Item label="Barkod">{order?.urunModel?.barkodNo}</Descriptions.Item>
          <Descriptions.Item label="Seri No / Imei No">{order?.seriNo}</Descriptions.Item>
          <Descriptions.Item label="Tedarikçi">
            {order?.kullaniciModel?.kullaniciAdi} - {order?.kullaniciModel?.ad} {order?.kullaniciModel?.soyad}
          </Descriptions.Item>
          <Descriptions.Item label="Alış Pazaryeri">{order?.pazaryeriModel?.pazaryeriAdi}</Descriptions.Item>
          <Descriptions.Item label="Alış Sipariş No">{order?.siparisNo}</Descriptions.Item>
          <Descriptions.Item label="Alış Sipariş Tarihi">{dateFormat(order?.siparisTarihi)}</Descriptions.Item>
          <Descriptions.Item label="Alış Kargo Firması">{order?.alisKargoFirmasiModel?.kargoFirmasiAdi}</Descriptions.Item>
          <Descriptions.Item label="Alış Kargo No">
            <CargoUrl cargoId={order?.alisKargoFirmasiModel?.kargoFirmasiId} cargoNo={order?.kargoNo} />
          </Descriptions.Item>
          <Descriptions.Item label="Alış Kargo Tarihi">{dateFormat(order?.siparisKargoTarihi)}</Descriptions.Item>
          <Descriptions.Item label="Depo Giriş Tarihi">{dateFormat(order?.siparisDepoGirisTarihi)}</Descriptions.Item>
          <Descriptions.Item label="Alış Fiyat">{moneyFormat(order?.alisFiyat)}</Descriptions.Item>
          <Descriptions.Item label="Ödenecek Fiyat">{moneyFormat(order?.tedarikciOdenecekFiyat)}</Descriptions.Item>
          <Descriptions.Item label="Ödenen Fiyat">{moneyFormat(order?.tedarikciOdenenFiyat)}</Descriptions.Item>
          <Descriptions.Item label="Ödenecek Tarih">{dateFormat(order?.tedarikciOdenecekTarih)}</Descriptions.Item>
          <Descriptions.Item label="Ödenenen Tarih">{dateFormat(order?.tedarikciOdenenTarih)}</Descriptions.Item>
          <Descriptions.Item label="Tedarikçi Açıklama">{order?.tedarikciAciklama}</Descriptions.Item>
          <Descriptions.Item label="Satış Pazaryeri">{order?.satisPazaryeriModel?.pazaryeriAdi} </Descriptions.Item>
          <Descriptions.Item label="Satış Fiyat">{moneyFormat(order?.satisFiyat)}</Descriptions.Item>
          <Descriptions.Item label="Satış Tarihi">{dateFormat(order?.satisTarihi)}</Descriptions.Item>
          <Descriptions.Item label="Satış Kargo Firması">{order?.satisKargoFirmasiModel?.kargoFirmasiAdi}</Descriptions.Item>
          <Descriptions.Item label="Satış Kargo No">{order?.satisKargoNo}</Descriptions.Item>
          <Descriptions.Item label="Satış Kargo Tarihi">{dateFormat(order?.satisKargoTarihi)}</Descriptions.Item>
          <Descriptions.Item label="Alış Fatura">
            <Button
              className="w-full"
              type="primary"
              disabled={!Boolean(order?.alisFaturasiModel?.dokumanId)}
              onClick={() => downloadFile(order.alisFaturasiModel)}
              icon={<CloudDownloadOutlined />}
            >
              İndir
            </Button>
          </Descriptions.Item>
          <Descriptions.Item label="Fatura Onaylandı Mı">{order?.alisFaturasiOnaylandi === 'E' ? 'Evet' : 'Hayır'}</Descriptions.Item>
          <Descriptions.Item label="Satış Fatura">
            <Button
              className="w-full"
              type="primary"
              disabled={!Boolean(order?.satisFaturasiModel?.dokumanId)}
              onClick={() => downloadFile(order.satisFaturasiModel)}
              icon={<CloudDownloadOutlined />}
            >
              İndir
            </Button>
          </Descriptions.Item>
          <Descriptions.Item label=""> </Descriptions.Item>
        </Descriptions>
        {kullaniciModel.kullaniciTipi === ROL.ADMIN && (
          <Collapse accordion className="mt-4">
            <Collapse.Panel header="Düzenle" key="1">
              <div className="grid grid-cols-[120px_1fr_120px_1fr] items-center gap-y-6 gap-x-4">
                <label className="whitespace-nowrap">Pazaryeri :</label>
                <Select options={markets} fieldNames={{ label: 'pazaryeriAdi', value: 'pazaryeriId' }} form={form} name="pazaryeriId" />

                <label className="whitespace-nowrap">Kategori :</label>
                <Select options={categories} fieldNames={{ label: 'kategoriAdi', value: 'kategoriId' }} form={form} name="kategoriId" />

                <label className="whitespace-nowrap">Ürün :</label>
                <Select options={productByCategory} fieldNames={{ label: 'urunAdi', value: 'urunId' }} form={form} searchFields={['barkodNo']} placeholder="Ürün Adı veya Barkod" name="urunId" />

                <label className="whitespace-nowrap">Depoyeri :</label>
                <Select options={warehouses} fieldNames={{ label: 'depoyeriAdi', value: 'depoyeriId' }} form={form} name="depoyeriId" />

                <label className="whitespace-nowrap">Alış Fiyat :</label>
                <InputNumber form={form} name="alisFiyat" min={0} />

                <label className="whitespace-nowrap">Sipariş No :</label>
                <Input form={form} name="siparisNo" />

                <label className="whitespace-nowrap">Sipariş Tarihi :</label>
                <DatePicker form={form} name="siparisTarihi" />

                <label className="whitespace-nowrap">Alış Kargo Firması :</label>
                <Select options={cargos} fieldNames={{ label: 'kargoFirmasiAdi', value: 'kargoFirmasiId' }} form={form} name="kargoFirmasiId" />

                <label className="whitespace-nowrap">Alış Kargo No :</label>
                <Input form={form} name="kargoNo" />

                <label className="whitespace-nowrap">Sipariş Kargo Tarihi :</label>
                <DatePicker form={form} name="siparisKargoTarihi" />

                <label className="whitespace-nowrap">Açiklama :</label>
                <Input form={form} name="tedarikciAciklama" />
              </div>

              <Button type="primary" className="mt-2" onClick={update} icon={<ArrowUpOutlined />}>
                Güncelle
              </Button>
            </Collapse.Panel>
          </Collapse>
        )}
      </Drawer>
    </>
  );
};
