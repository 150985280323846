import { UploadFile } from 'antd';
import { saveAs } from 'file-saver';
import { DokumanModel } from '../types';
import { request } from '../utils';

export const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays: Uint8Array[] = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers: number[] = Array.from({ length: slice.length });
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

export const downloadFile = (file: DokumanModel, byteArray: string) => {
  let fileType = 'image/png';
  if(file.dokumanAdi?.includes('.pdf')) {
    fileType = 'application/pdf';
  }
  saveAs(b64toBlob(byteArray, fileType), file.dokumanAdi);
};

export default {
  download: async (file: DokumanModel) => {
    if (file.dokumanId && file.dokumanAdi) {
      const response = await request('/dokuman/download', 'POST', null, { fileName: file.dokumanId });
      if (response.ok) {
        downloadFile(file, response.data);
      }
    }
  },
  getDocument: async (file: DokumanModel) => {
    if (file.dokumanId && file.dokumanAdi) {
      const response = await request('/bhr/dokuman/download', 'POST', null, { fileName: file.dokumanId });
      return response;
    }
  },
  uploadAlisFatura: async (file: UploadFile, siparisIdList: number[], faturaTarihi: Date, faturaTipiId: number) => {
    const formData = new FormData();
    formData.append('multipartFile', file as unknown as File);
    formData.append('faturaTarihi', faturaTarihi.toISOString().slice(0,10));
    formData.append('bhrFaturaTipiId', faturaTipiId.toString());

    siparisIdList.forEach(siparisId => {
      formData.append('bhrSiparisIdList', siparisId.toString());
    })

    return request('/bhr/dokuman/alis-fatura-yukle', 'POST', formData);
  },
  uploadSatisFatura: async (file: UploadFile, siparisId: number) => {
    const formData = new FormData();
    formData.append('multipartFile', file as unknown as File);
    formData.append('siparisId', siparisId.toString());

    return request('/dokuman/satis-fatura-yukle', 'POST', formData);
  },
  efatura: async (bhrSiparisIdList: number[], faturaTarihi: Date, bhrFaturaTipiId: number) => await request('/bhr/dokuman/efatura-onaya-gonder', 'POST', null, { bhrSiparisIdList, faturaTarihi : faturaTarihi.toISOString().slice(0,10), bhrFaturaTipiId }),
  approve: async (bhrSiparisId: number, faturaTarihi: string) => await request('/bhr/dokuman/alis-fatura-onayla', 'POST', null, { bhrSiparisId, faturaTarihi }),
  reject: async (bhrSiparisIdList: number[], redAciklama: string) => await request('/bhr/dokuman/alis-fatura-reddet', 'POST', null, { bhrSiparisIdList, redAciklama }),
  cancel: async (bhrSiparisIdList: number[], iptalAciklama: string) => await request('/bhr/dokuman/fatura-iptal-et', 'POST', null, { bhrSiparisIdList, iptalAciklama }),
  approveLess: async (bhrSiparisIdList: number[]) => await request('/bhr/dokuman/faturasiz-onayla', 'POST', null, { bhrSiparisIdList }),
};
