import { ColumnsType } from 'antd/es/table';
import { useCallback, useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';

import * as API from '../api';
import { UserContext } from '../contexts/UserContext';
import { AKTIF_PASIF, ROL, SiparisModel, SIPARIS_STATU, EVET_HAYIR, DokumanModel } from '../types';

import { createOrderColumns } from '../utils';
import { useMultiSelect } from './useMultiSelect';
import { useSingleSelect } from './useSingleSelect';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { downloadFile } from '../api/files';

export const useOrderList = (faturaBekleyenler = false, onayBekleyenler = false, sahipsizler = false) => {
  const kullaniciModel = useContext(UserContext);
  const isAdmin = kullaniciModel.kullaniciTipi === ROL.ADMIN;

  const [selectedUserId, setSelectedUserId] = useState<number | undefined>(kullaniciModel.kullaniciTipi === ROL.ADMIN ? undefined : kullaniciModel.kullaniciId);
  const [siparisStatu, setSiparisStatu] = useState<SIPARIS_STATU | undefined>(isAdmin ? SIPARIS_STATU.SIP_DEPO : undefined);
  const [odemeYapildi, setOdemeYapildi] = useState<EVET_HAYIR | undefined>(isAdmin ? undefined : EVET_HAYIR.HAYIR);
  const [faturaOnay, setFaturaOnay] = useState<EVET_HAYIR | undefined>();
  const [efaturaMi, setEfaturaMi] = useState<EVET_HAYIR | undefined>();
  const [depoyeri, setDepoyeri] = useState<number | undefined>();
  const [pazaryeri, setPazaryeri] = useState<number | undefined>();
  const [kategori, setKategori] = useState<number | undefined>();
  const [marka, setMarka] = useState<number | undefined>();
  const [urun, setUrun] = useState<number | undefined>();
  const [kart, setKart] = useState<number | undefined>();
  const [siparisNo, setSiparisNo] = useState<string | undefined>();
  const [siparisId, setSiparisId] = useState<number | undefined>();
  const [alisFiyatMin, setAlisFiyatMin] = useState<number | null>();
  const [alisFiyatMax, setAlisFiyatMax] = useState<number | null>();
  const [siparisTarihi, setSiparisTarihi] = useState<[dayjs.Dayjs, dayjs.Dayjs] | null>();
  const [satisTarihi, setSatisTarihi] = useState<[dayjs.Dayjs, dayjs.Dayjs] | null>();
  const [faturaTarihi, setFaturaTarihi] = useState<dayjs.Dayjs | null>();
  const [odemeTarih, setOdemeTarihi] = useState<[dayjs.Dayjs, dayjs.Dayjs] | null>();

  const [isPdf, setIsPdf] = useState(false);
  const [doc, setDoc] = useState<DokumanModel>();
  const [byteArray, setByteArray] = useState<string>();
  const [isDocModalOpen, setIsDocModalOpen] = useState(false);

  const [users = [], _, getUsers] = API.USERS.useFetchTedarikci({});
  const [list = [], loading, _getList] = API.ORDERS.useFetch({});
  const { selected, setSelected, onSelectClick } = useSingleSelect('siparisId', list);
  const { selectedKeyList, selectedList, onMultiSelectClick } = useMultiSelect('siparisId', list);

  const columns: ColumnsType<SiparisModel> = createOrderColumns(faturaBekleyenler, onayBekleyenler, kullaniciModel.kullaniciTipi === ROL.ADMIN);

  const getList = useCallback(async () => {
    const filterData: SiparisModel = {
      firmaModel: kullaniciModel.firmaModel,
    };

    if (selectedUserId) {
      filterData.kullaniciModel = {
        kullaniciId: selectedUserId,
      };
    }

    if (depoyeri) {
      filterData.bhrDepoyeriModel = {
        depoyeriId: depoyeri,
      };
    }

    if (pazaryeri) {
      filterData.bhrPazaryeriModel = {
        pazaryeriId: pazaryeri,
      };
    }

    if (kategori) {
      filterData.bhrKategoriModel = {
        kategoriId: kategori,
      };
    }

    if (marka) {
      filterData.bhrMarkaModel = {
        markaId: marka,
      };
    }

    if (urun) {
      filterData.bhrUrunModel = {
        urunId: urun,
      };
    }

    if (kart) {
      filterData.bhrKrediKartiModel = {
        krediKartiId: kart,
      };
    }

    if (siparisNo) {
      filterData.siparisNo = siparisNo;
    }

    if (siparisId) {
      filterData.siparisId = siparisId;
    }

    if (siparisStatu) {
      filterData.siparisStatu = siparisStatu;
    }

    if (odemeYapildi) {
      filterData.odemesiYapildi = odemeYapildi;
    }

    if (faturaOnay) {
      filterData.alisFaturasiOnaylandi = faturaOnay;
    }

    if (efaturaMi) {
      filterData.efaturaMi = efaturaMi;
    }

    if (alisFiyatMin) {
      filterData.alisFiyatMin = alisFiyatMin;
    }

    if (alisFiyatMax) {
      filterData.alisFiyatMax = alisFiyatMax;
    }

    if (siparisTarihi && siparisTarihi.length === 2) {
      const siparisTarihiStart = siparisTarihi[0].toISOString();
      const siparisTarihiTarihEnd = siparisTarihi[1].toISOString();

      filterData.siparisTarihiStart = siparisTarihiStart;
      filterData.siparisTarihiEnd = siparisTarihiTarihEnd;
    }

    if (satisTarihi && satisTarihi.length === 2) {
      const satisTarihiStart = satisTarihi[0].toISOString();
      const satisTarihiTarihEnd = satisTarihi[1].toISOString();

      filterData.satisTarihiStart = satisTarihiStart;
      filterData.satisTarihiEnd = satisTarihiTarihEnd;
    }

    if (faturaTarihi) {
      const faturaLastDay = faturaTarihi.daysInMonth();

      const faturaTarihiStart = faturaTarihi.date(1).toISOString();
      const faturaTarihiEnd = faturaTarihi.date(faturaLastDay).toISOString();

      filterData.faturaTarihiStart = faturaTarihiStart;
      filterData.faturaTarihiEnd = faturaTarihiEnd;
    }

    if (odemeTarih && odemeTarih.length === 2) {
      const tedarikciOdenecekTarihStart = odemeTarih[0].toISOString();
      const tedarikciOdenecekTarihEnd = odemeTarih[1].toISOString();

      filterData.tedarikciOdenecekTarihStart = tedarikciOdenecekTarihStart;
      filterData.tedarikciOdenecekTarihEnd = tedarikciOdenecekTarihEnd;
    }

    _getList(filterData);

    onMultiSelectClick([]);
  }, [
    selectedUserId,
    siparisStatu,
    odemeYapildi,
    faturaOnay,
    efaturaMi,
    kategori,
    marka,
    urun,
    kart,
    depoyeri,
    pazaryeri,
    siparisTarihi,
    satisTarihi,
    faturaTarihi,
    odemeTarih,
    siparisNo,
    siparisId,
    alisFiyatMin,
    alisFiyatMax,
  ]);

  const pasif = useCallback(
    async (orders?: SiparisModel[]) => {
      if (orders) {
        await API.ORDERS.pasif(orders);
        getList();
      }
    },
    [getList]
  );

  const depoda = useCallback(
    async (orders?: SiparisModel[]) => {
      if (orders) {
        const response = await API.ORDERS.depoda(orders);
        getList();

        return response;
      }
    },
    [getList]
  );

  const depodaIptal = useCallback(
    async (orders?: SiparisModel[]) => {
      if (orders) {
        const response = await API.ORDERS.depodaIptal(orders);
        getList();

        return response;
      }
    },
    [getList]
  );

  const satis = useCallback(
    async (orders?: SiparisModel[]) => {
      if (orders) {
        const response = await API.ORDERS.satis(orders);
        getList();

        return response;
      }
    },
    [getList]
  );

  const satisIptal = useCallback(
    async (orders?: SiparisModel[]) => {
      if (orders) {
        const response = await API.ORDERS.satisIptal(orders);
        getList();

        return response;
      }
    },
    [getList]
  );

  const iptal = useCallback(
    async (orders?: SiparisModel[]) => {
      if (orders) {
        const response = await API.ORDERS.iptal(orders);
        getList();

        return response;
      }
    },
    [getList]
  );

  const iptalIptal = useCallback(
    async (orders?: SiparisModel[]) => {
      if (orders) {
        const response = await API.ORDERS.iptalIptal(orders);
        getList();

        return response;
      }
    },
    [getList]
  );

  const odemeGeldi = useCallback(
    async (orders?: SiparisModel[]) => {
      if (orders) {
        const response = await API.ORDERS.odemeGeldi(orders);
        getList();

        return response;
      }
    },
    [getList]
  );

  const odemeGeldiIptal = useCallback(
    async (orders?: SiparisModel[]) => {
      if (orders) {
        const response = await API.ORDERS.odemeGeldiIptal(orders);
        getList();

        return response;
      }
    },
    [getList]
  );

  const downloadAll = useCallback(
    async (orders?: SiparisModel[]) => {
      if (orders) {
        const ids: string[] = [];
        const files: {
          order: SiparisModel;
          file: string;
        }[] = [];

        for (let i = 0; i < orders.length; i++) {
          const order = orders[i];
          if (order.alisFaturasiModel && order.efaturaMi === EVET_HAYIR.HAYIR && order?.alisFaturasiModel?.dokumanId && !ids.includes(order?.alisFaturasiModel?.dokumanId)) {
            ids.push(order?.alisFaturasiModel?.dokumanId);

            const response = await API.FILES.getDocument(order?.alisFaturasiModel);
            if (response?.ok) {
              files.push({
                order,
                file: response.data,
              });
            }
          }
        }

        if (files.length > 0) {
          if (files.length > 1) {
            const zip = new JSZip();
            files.forEach((file) => {
              zip.file(file.order?.alisFaturasiModel?.dokumanAdi || '', file.file, { base64: true });
            });

            const blob = await zip.generateAsync({ type: 'blob' });

            saveAs(blob, 'Faturalar.zip');
          } else {
            const file = files[0];

            if (file?.order?.alisFaturasiModel) {
              downloadFile(file.order.alisFaturasiModel, file.file);
            }
          }
        }
      }
    },
    [getList]
  );

  const odemeYap = useCallback(
    async (orders?: SiparisModel[]) => {
      if (orders) {
        const response = await API.ORDERS.odemeYap(orders);
        getList();

        return response;
      }
    },
    [getList]
  );

  const odemeGeriAl = useCallback(
    async (orders?: SiparisModel[]) => {
      if (orders) {
        const response = await API.ORDERS.odemeGeriAl(orders);
        getList();

        return response;
      }
    },
    [getList]
  );

  const getDoc = useCallback(async () => {
    if (selectedList && selectedList.length > 0) {
      const selected = selectedList[0];
      setByteArray(undefined);
      setDoc(undefined);
      if (selected?.alisFaturasiModel) {
        const res = await API.FILES.getDocument(selected.alisFaturasiModel);

        let pdf = false;
        if (selected.alisFaturasiModel.dokumanAdi) {
          const filename = selected.alisFaturasiModel.dokumanAdi.split('.');
          const type = filename[filename.length - 1];

          pdf = type.toLowerCase() === 'pdf';
        }

        setIsPdf(pdf);
        setByteArray(res?.data);
        setDoc(selected.alisFaturasiModel);
        setIsDocModalOpen(true);
      }
    }
  }, [selectedList, setIsPdf, setDoc, setByteArray, setIsDocModalOpen]);

  useEffect(() => {
    if (kullaniciModel.kullaniciTipi === ROL.ADMIN) {
      getUsers(kullaniciModel.firmaModel);
    }
  }, []);

  useEffect(() => {
    getList();
  }, [
    selectedUserId,
    siparisStatu,
    odemeYapildi,
    faturaOnay,
    efaturaMi,
    kategori,
    marka,
    urun,
    kart,
    depoyeri,
    pazaryeri,
    siparisTarihi,
    satisTarihi,
    faturaTarihi,
    odemeTarih,
    siparisNo,
    siparisId,
    alisFiyatMin,
    alisFiyatMax,
  ]);

  return {
    users,
    selectedUserId,
    setSelectedUserId,
    siparisStatu,
    setSiparisStatu,
    odemeYapildi,
    odemeGeriAl,
    setOdemeYapildi,
    faturaOnay,
    setFaturaOnay,
    efaturaMi,
    setEfaturaMi,
    depoyeri,
    setDepoyeri,
    pazaryeri,
    setPazaryeri,
    kategori,
    setKategori,
    marka,
    setMarka,
    urun,
    setUrun,
    kart,
    setKart,
    siparisNo,
    setSiparisNo,
    siparisId,
    setSiparisId,
    siparisTarihi,
    setSiparisTarihi,
    satisTarihi,
    setSatisTarihi,
    faturaTarihi,
    setFaturaTarihi,
    odemeTarih,
    setOdemeTarihi,
    alisFiyatMin,
    setAlisFiyatMin,
    alisFiyatMax,
    setAlisFiyatMax,
    list,
    loading,
    getList,
    columns,
    selected,
    setSelected,
    onSelectClick,
    selectedKeyList,
    selectedList,
    onMultiSelectClick,
    pasif,
    depoda,
    depodaIptal,
    satis,
    satisIptal,
    odemeYap,
    iptal,
    iptalIptal,
    odemeGeldi,
    odemeGeldiIptal,
    downloadAll,
    isPdf,
    doc,
    byteArray,
    isDocModalOpen,
    setIsDocModalOpen,
    getDoc,
    isAdmin,
  };
};
