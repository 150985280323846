import { DropboxOutlined } from '@ant-design/icons';
import { Card, Collapse, Table } from 'antd';
import React, { useCallback, useContext } from 'react';
import { z } from 'zod';

import * as API from '../../api';
import { DictFormActions, DictTableActions, DictTableStatus, Input, InputNumber, Select } from '../../components';
import { UserContext } from '../../contexts/UserContext';
import { useFillForm, useWindowSize } from '../../hooks';
import { AKTIF_PASIF, BhrSepetUrunModel, MarkaModel, ROL } from '../../types';

const BrandForm = z.object({
  sepetAdi: z.string().min(1, 'Zorunludur'),
  urunCesiti: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }).min(1, "Ürün Çeşiti 1'den büyük olmalı."),
});

export const Sepet = () => {
  const { sm } = useWindowSize();
  const { firmaModel } = useContext(UserContext);

  const [products = []] = API.PRODUCT.useFetchActive({ init: true, initBody: firmaModel });

  const [list = [], loading, getList] = API.SEPET.useFetch({ init: true, initBody: firmaModel });
  const active = API.SEPET.useFetchActive({});
  const { form, selected, clear, fill } = useFillForm(BrandForm, { sepetAdi: '', urunCesiti: 1 });

  const save = useCallback(async () => {
    const { success, data } = form.parse();
    if (success) {
      const saveArray: BhrSepetUrunModel[] = [];

      for (let i = 0; i < form.value['urunCesiti']; i++) {
        const urunId = form.value['cesit_urunId' + i];
        const adet = form.value['cesit_adet' + i] || 1;

        const saveData = {
          bhrSepetModel: {
            firmaModel: firmaModel,
            sepetAdi: data.sepetAdi,
          },
          bhrUrunModel: {
            urunId: urunId,
          },
          adet: adet,
        };

        saveArray.push(saveData);
      }

      console.log(saveArray);
      await API.SEPET.save(saveArray);

      clear();
      getList(firmaModel);
      active[2](firmaModel);
    } else {
      form.setAllTouched();
    }
  }, [form, selected]);

  const remove = useCallback(async (values: { sepetId: any; aktifPasif: AKTIF_PASIF }) => {
    await API.SEPET.activePassive({
      sepetId: values.sepetId,
      aktifPasif: values.aktifPasif === AKTIF_PASIF.AKTIF ? AKTIF_PASIF.PASIF : AKTIF_PASIF.AKTIF,
    });

    clear();
    getList(firmaModel);
  }, []);

  const columns = [
    {
      title: 'Sepet Adı',
      dataIndex: 'sepetAdi',
      key: 'sepetAdi',
    },
    {
      title: 'Durum',
      dataIndex: 'aktifPasif',
      key: 'aktifPasif',
      width: sm ? 100 : 70,
      render: (_: any, record: MarkaModel) => <DictTableStatus record={record} />,
    },
    {
      title: 'İşlemler',
      key: 'actions',
      width: sm ? 240 : 120,
      render: (_: any, record: MarkaModel) => <DictTableActions record={record} remove={remove} fill={fill} noEdit />,
    },
  ];

  const cesitRange = Array.from({ length: Number(form.value.urunCesiti || 0) }).fill(0);

  const getTotal = () => {
    let total = 0;
    for (let i = 0; i < form.value['urunCesiti']; i++) {
      const urunId = form.value['cesit_urunId' + i];
      const adet = form.value['cesit_adet' + i] || 1;
      const urun = products.find((product) => product.urunId == urunId);
      const fiyat = (urun?.alisFiyat ?? 0) * adet;

      total += fiyat;
    }

    return total || '';
  };

  return (
    <div className="flex flex-col gap-2">
      <Collapse accordion defaultActiveKey="1">
        <Collapse.Panel header={<span className="text-base font-semibold ">Yeni Sepet Tanımlama</span>} key="1">
          <div className="flex gap-8">
            <div className="grid grid-cols-[100px_160px] grid-rows-[32px_32px_32px] items-center gap-y-6 gap-x-4 sm:max-w-sm ">
              <label className="whitespace-nowrap">Sepet Adı :</label>
              <Input form={form} name="sepetAdi" />

              <label className="whitespace-nowrap">Ürün Çeşiti :</label>
              <InputNumber form={form} name="urunCesiti" min={1} />

              <label className="whitespace-nowrap">Toplam Fiyat :</label>
              <span>{getTotal()}</span>

              <DictFormActions save={save} clear={clear} />
            </div>
            <div className="max-h-[780px] gap-4 overflow-scroll grid grid-cols-3 grid-rows-[min-content_min-content_min-content] pr-6">
              {cesitRange.map((_, index) => (
                <div
                  key={'cesit_' + index}
                  className="h-max grid grid-cols-[70px_200px] grid-rows-[32px_32px] items-center justify-start gap-y-6 gap-x-4 sm:max-w-sm p-2 bg-slate-100 rounded border border-solid border-slate-300"
                >
                  <label className="whitespace-nowrap">Ürün :</label>
                  <Select
                    options={products}
                    fieldNames={{ label: 'urunAdi', value: 'urunId' }}
                    searchFields={['barkodNo']}
                    placeholder="Ürün Adı veya Barkod"
                    form={form}
                    name={'cesit_urunId' + index}
                  />

                  <label className="whitespace-nowrap">Adet :</label>
                  <InputNumber form={form} name={'cesit_adet' + index} min={1} />
                </div>
              ))}
            </div>
          </div>
        </Collapse.Panel>
      </Collapse>

      <Table
        scroll={{ y: sm ? 'calc(100svh - 240px)' : 'calc(100svh - 190px)' }}
        pagination={false}
        rowKey="sepetId"
        size="small"
        bordered
        showSorterTooltip={false}
        columns={columns}
        dataSource={list}
        loading={loading}
      />
    </div>
  );
};

Sepet.path = 'sepet';
Sepet.title = 'Sepet';
Sepet.group = 'dicts';
Sepet.roles = [ROL.ADMIN];
Sepet.icon = <DropboxOutlined />;
