import { PlusCircleOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import React, { Fragment, useCallback, useContext, useEffect, useMemo } from 'react';
import { z } from 'zod';
import { message } from 'antd';

import * as API from '../../api';
import { DatePicker, DictFormActions, Input, InputNumber, Select } from '../../components';
import { UserContext } from '../../contexts/UserContext';
import { useFillForm } from '../../hooks';
import { ROL, SiparisModel } from '../../types';

const OrderForm = z.object({
  kullaniciId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  depoyeriId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  siparisNo: z.string().min(1, 'Zorunludur'),
  siparisTarihi: z.date({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  tedarikciAciklama: z.string().optional(),
  tedarikciAciklama2: z.string().optional(),
  adminAciklama: z.string().optional(),
  sepetId: z.number().optional(),
});

export const NewOrderSepet = ({ onSaveDone = () => null, fillOrder }: { onSaveDone?: CallableFunction; fillOrder?: SiparisModel | null }) => {
  const kullaniciModel = useContext(UserContext);
  const isAdmin = kullaniciModel.kullaniciTipi === ROL.ADMIN;

  const [users = [], _, getUsers] = API.USERS.useFetchTedarikci({});
  const [sepets = []] = API.SEPET.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [sepetDetail = [], sepetLoading, getSepetDetail] = API.SEPET.useFetchDetail({ init: false });
  const [products = []] = API.PRODUCT.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [warehouses = []] = API.WAREHOUSES.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [warehousesTed = []] = API.WAREHOUSES.useFetchActiveTed({ init: true, initBody: kullaniciModel.firmaModel });

  const { form, clear, fill } = useFillForm(OrderForm, {
    kullaniciId: kullaniciModel.kullaniciTipi === ROL.ADMIN ? undefined : kullaniciModel.kullaniciId,
    depoyeriId: undefined,
    siparisNo: '',
    siparisTarihi: new Date(),
    tedarikciAciklama: '',
    tedarikciAciklama2: '',
    urunCesiti: 1,
    sepetId: undefined,
  });

  useEffect(() => {
    if (kullaniciModel.kullaniciTipi === ROL.ADMIN) {
      getUsers(kullaniciModel.firmaModel);
    }
  }, []);

  useEffect(() => {
    if (form.value.sepetId) {
      getSepetDetail({
        sepetId: form.value.sepetId,
      });
    }
  }, [form.value.sepetId]);

  const save = useCallback(async () => {
    const { success, data } = form.parse();
    if (success) {
      const siparisTarihi = new Date(data.siparisTarihi);
      siparisTarihi.setHours(0);
      siparisTarihi.setMinutes(0);
      siparisTarihi.setSeconds(0);
      siparisTarihi.setMilliseconds(0);
      const formedData = {
        bhrPazaryeriModel: {
          pazaryeriId: 101,
        },

        bhrDepoyeriModel: {
          depoyeriId: data.depoyeriId,
        },
        siparisNo: data.siparisNo,
        siparisTarihi: siparisTarihi.toISOString(),
        tedarikciAciklama: data.tedarikciAciklama,
        tedarikciAciklama2: data.tedarikciAciklama2,
        firmaModel: kullaniciModel.firmaModel,
        kullaniciModel: {
          kullaniciId: data.kullaniciId,
        },
        urunCesiti: data.urunCesiti,
      };

      const saveArray: SiparisModel[] = [];
      for (let i = 0; i < sepetDetail.length; i++) {
        const sepetUrun = sepetDetail[i];
        const kategoriId = 81;
        const markaId = 301;
        const urunId = sepetUrun.bhrUrunModel?.urunId;
        const adet = sepetUrun.adet ?? 1;
        const alisFiyat = sepetUrun.bhrUrunModel?.alisFiyat;

        for (let j = 0; j < adet; j++) {
          const saveData = {
            ...formedData,
            bhrKategoriModel: {
              kategoriId: kategoriId,
            },
            bhrMarkaModel: {
              markaId: markaId,
            },
            bhrUrunModel: {
              urunId: urunId,
            },
            adet: adet,
            alisFiyat: alisFiyat,
          };

          saveArray.push(saveData);
        }
      }

      await API.ORDERS.save(saveArray);

      onSaveDone();
      clear();
    } else {
      form.setAllTouched();
    }
  }, [form]);

  const getTotal = () => {
    if (sepetDetail) {
      let total = 0;
      for (let i = 0; i < sepetDetail.length; i++) {
        const sepetUrun = sepetDetail[i];
        const fiyat = (sepetUrun.bhrUrunModel?.alisFiyat ?? 0) * (sepetUrun?.adet || 1);

        total += fiyat;
      }

      return total || '';
    }

    return '';
  };

  return (
    <div className="flex flex-col gap-2">
      <Card title="Sepetten Yeni Sipariş Ekleme">
        <div className="flex gap-8">
          <div className="grid grid-cols-[100px_160px] grid-rows-[32px_32px_32px_32px_32px_32px_32px_32px_32px_32px] items-center gap-y-6 gap-x-4 sm:max-w-sm ">
            {kullaniciModel.kullaniciTipi === ROL.ADMIN && (
              <>
                <label className="whitespace-nowrap">Tedarikçi :</label>
                <Select
                  options={users.map((user) => ({ ...user, label: `${user.kullaniciAdi} - ${user.ad} ${user.soyad}` }))}
                  fieldNames={{ label: 'label', value: 'kullaniciId' }}
                  form={form}
                  name="kullaniciId"
                />
              </>
            )}

            <label className="whitespace-nowrap">Sipariş No :</label>
            <Input form={form} name="siparisNo" />

            <label className="whitespace-nowrap">Sipariş Tarih :</label>
            <DatePicker form={form} name="siparisTarihi" />

            <label className="whitespace-nowrap">Hesap Maili :</label>
            <Input form={form} name="tedarikciAciklama" />

            <label className="whitespace-nowrap">Kart :</label>
            <Input form={form} name="tedarikciAciklama2" />

            <label className="whitespace-nowrap">Depoyeri :</label>
            <Select options={isAdmin ? warehouses : warehousesTed} fieldNames={{ label: 'depoyeriAdi', value: 'depoyeriId' }} form={form} name="depoyeriId" />

            <label className="whitespace-nowrap">Sepet :</label>
            <Select options={sepets} fieldNames={{ label: 'sepetAdi', value: 'sepetId' }} placeholder="Sepet Adı" form={form} name={'sepetId'} />

            <label className="whitespace-nowrap">Toplam Fiyat :</label>
            <span>{getTotal()}</span>

            <DictFormActions save={save} clear={clear} />
          </div>
          <div className="max-h-[780px] gap-4 overflow-scroll grid grid-cols-3 grid-rows-[min-content] pr-6">
            {sepetDetail.map((sepetUrun, index) => (
              <div
                key={'cesit_' + index}
                className="h-max grid grid-cols-[70px_200px] grid-rows-[32px_32px] items-center justify-start gap-y-1 gap-x-4 sm:max-w-sm p-2 bg-slate-100 rounded border border-solid border-slate-300"
              >
                <label className="whitespace-nowrap">Ürün :</label>
                <span>{sepetUrun.bhrUrunModel?.urunAdi}</span>

                <label className="whitespace-nowrap">Adet :</label>
                <span>{sepetUrun.adet}</span>
              </div>
            ))}
          </div>
        </div>
      </Card>
    </div>
  );
};

NewOrderSepet.path = 'new-order-sepet';
NewOrderSepet.title = 'Sepetten Yeni Sipariş';
NewOrderSepet.group = 'supplier';
NewOrderSepet.roles = [ROL.TEDARIKCI, ROL.ADMIN];
NewOrderSepet.icon = <PlusCircleOutlined />;
