import React, { useMemo, useState } from 'react';
import { DokumanModel } from '../types';
import { Document, Page } from 'react-pdf/dist/esm/entry.parcel2';

type Props = {
  doc?: DokumanModel;
  byteArray?: string;
};

export const DocViewer = ({ doc, byteArray }: Props) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const base64 = useMemo(() => {
    return `data:application/pdf;base64,${byteArray}`;
  }, [byteArray]);

  function onDocumentLoadSuccess(a) {
    setNumPages(a._pdfInfo.numPages);
  }

  return (
    <div className="flex justify-center">
      <Document file={base64} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page scale={2} key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
    </div>
  );
};
