import { message } from 'antd';
import { auth } from '../contexts/UserContext';
//http://172.20.10.3:8080/
const BASE_URL = '/stu-be';
//const BASE_URL = 'http://195.85.216.67:80/stu-be';
//const BASE_URL = 'http://172.20.10.3:8080';

const defaultOptions: RequestInit = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const request = async (url: string, method = 'GET', data: any = null, params: any = null, options: RequestInit = defaultOptions, noMessage = false) => {
  localStorage.setItem('stok.atlassoft.last', new Date().getTime() + '');
  
  const searchParams = new URLSearchParams(params).toString();
  const urlTail = params ? `?${searchParams}` : '';

  const fullUrl = `${BASE_URL}${url}${urlTail}`;

  options = { ...defaultOptions, ...options };

  if (!options.headers) {
    options.headers = {};
  }

  if (!options.headers['Authorization']) {
    options.headers['Authorization'] = 'Basic ' + auth.basic;
  }

  let body: any = null;
  if (data instanceof FormData) {
    body = data;
    delete options.headers['Content-Type'];
  } else {
    options.headers['Content-Type'] = 'application/json';
    body = data ? JSON.stringify(data) : null;
  }

  try {
    const response = await fetch(fullUrl, {
      ...options,
      method,
      body,
    });

    const responseData = await response.json();
    message.destroy()
    if (responseData.sonuc) {
      !noMessage && message.success(responseData.message);
    } else if (responseData.sonuc === false) {
      message.error(responseData.message);
    }

    return {
      ok: response.ok,
      status: response.status,
      statusText: response.statusText,
      data: responseData?.data,
      error: response.ok ? null : responseData,
    };
  } catch (error) {
    console.error('Request Error : ', fullUrl, '\n', error);

    return {
      ok: false,
      error: error,
    };
  }
};
