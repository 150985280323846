import { useFetch } from '../hooks';
import { KartModel, UseFetchOptions } from '../types';
import { request } from '../utils';

export default {
  useFetch: (config: UseFetchOptions) => useFetch<KartModel[]>('/bhr/kredi-karti/list-by-firma', { method: 'POST', ...config }),
  useFetchActive: (config: UseFetchOptions) => useFetch<KartModel[]>('/bhr/kredi-karti/aktif-list-by-firma', { method: 'POST', ...config }),
  useFetchActiveKullanici: (config: UseFetchOptions) => useFetch<KartModel[]>('/bhr/kredi-karti/aktif-list-by-kullanici', { method: 'POST', ...config }),
  save: async (kart: KartModel) => request('/bhr/kredi-karti/kayit', 'POST', kart),
  update: async (kart: KartModel) => request('/bhr/kredi-karti/guncelle', 'POST', kart),
  activePassive: async (kart: KartModel) => request('/bhr/kredi-karti/aktif-pasif', 'POST', kart),
};
