import React, { useCallback, useContext, useMemo, useState } from 'react';
import { UserContext } from '../contexts/UserContext';
import * as API from '../api';
import { Button, Card, Modal, Statistic, Table } from 'antd';
import { ContainerOutlined, FieldTimeOutlined, WalletOutlined } from '@ant-design/icons';
import { useWindowSize } from '../hooks';
import { numberFormat } from '../utils';
import { SiparisModel, SkorModel, UrunModel } from '../types';
import get from 'lodash.get';
import { NewOrder } from './supplier/NewOrder';

const compNum = (keys: string[] | keyof SkorModel) => (a: SkorModel, b: SkorModel) => {
  const aKey = Number(get(a, keys));
  const bKey = Number(get(b, keys));

  return aKey - bKey;
};

const compStr = (keys: string[] | keyof SkorModel) => (a: SkorModel, b: SkorModel) => {
  const aKey = String(get(a, keys));
  const bKey = String(get(b, keys));

  return aKey.localeCompare(bKey);
};

export const TedHome = () => {
  const kullaniciModel = useContext(UserContext);
  const { sm } = useWindowSize();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [indirim, setIndirim] = useState<string>('');
  const [fillOrder, setFillOrder] = useState<UrunModel[] | null>(null);

  const [data, loading, _getList] = API.DASHBOARD.useFetchTedarikci({ init: true, initBody: kullaniciModel });

  const alinacakList = useMemo(() => {
    const listNames = [
      data?.siradakiAlinacakUrunList ? '300TL' : null,
      data?.siradakiAlinacakUrunList2 ? 'Yüzde 15' : null,
      data?.siradakiAlinacakUrunList3 ? 'İndirimsiz' : null,
      data?.siradakiAlinacakUrunList4 ? 'Yüzde 20' : null,
    ].filter(Boolean);
    const lists = [data?.siradakiAlinacakUrunList, data?.siradakiAlinacakUrunList2, data?.siradakiAlinacakUrunList3, , data?.siradakiAlinacakUrunList4].filter(Boolean);
    if (lists.length > 0) {
      const rnd = Math.floor(Math.random() * lists.length);
      
      const list = lists[rnd] as any;
      list.rnd = rnd;
      list.name = listNames[rnd];

      let total = list.reduce(( acc : number, item : UrunModel ) => acc + (item.alisFiyat || 0), 0)

      if(list.name === '300TL') {
        total = total - 300;
      } else if(list.name === 'Yüzde 15') {
        total = total * 0.85;
      }  else if(list.name === 'Yüzde 20') {
        total = total * 0.80;
      }

      list.total = total;


      return list;
    }

    return [];
  }, [data?.siradakiAlinacakUrunList, data?.siradakiAlinacakUrunList2, data?.siradakiAlinacakUrunList3]);

  const tedarikciSkorModelList = useMemo(() => {
    if (data?.tedarikciSkorModelList) {
      return [...data?.tedarikciSkorModelList].sort((a, b) => b.bugunAlinan - a.bugunAlinan);
    }

    return [];
  }, [data?.tedarikciSkorModelList]);

  const satilAlPopup = useCallback(() => {
    const turList = {
      '300TL' : '300TL',
      'Yüzde 15' : '15YUZDE',
      'Yüzde 20' : '20YUZDE',
      'İndirimsiz' : 'INDIRIMSIZ',
    }
    const tur = turList[alinacakList.name];
    console.log(tur, alinacakList)

    setIndirim(tur);
    setFillOrder(alinacakList)
    setIsModalOpen(true);
  }, [alinacakList, setFillOrder])

  const handleCancel = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  return (
    <div className="flex flex-col gap-4 flex-wrap">
      <div className="flex gap-4">
        <Card className="h-24" bordered={false}>
          <Statistic title="Bu Ay Ödenecek" groupSeparator="." value={data?.buAyOdenecek || 0} valueStyle={{ color: '#3f8600' }} prefix={<WalletOutlined />} />
        </Card>
        <Card className="h-24" bordered={false}>
          <Statistic title="Bu Hafta Ödenecek" groupSeparator="." value={data?.buHaftaOdenecek || 0} valueStyle={{ color: '#3f8600' }} prefix={<WalletOutlined />} />
        </Card>
        <Card className="h-24" bordered={false}>
          <Statistic title="Bu Hafta Ödenecek" groupSeparator="." value={data?.odemesiBekleyen || 0} valueStyle={{ color: '#3f8600' }} prefix={<WalletOutlined />} />
        </Card>
      </div>

      <div className="flex flex-col gap-4 max-w-[1000px]">
        <Card>
          <div className="flex justify-between items-center gap-2">
            <h3 className="w-full text-center">Alınacak Ürün Listesi - {alinacakList.name}</h3>

            {alinacakList && alinacakList.length > 0 && <>
            <span className="whitespace-nowrap mr-4" >Sepet Toplam : {numberFormat(alinacakList.total)}</span>
              <Button htmlType="button"  type="primary" onClick={() => satilAlPopup()}>
              Satın Al
            </Button></>}
          </div>

          <Table dataSource={alinacakList} pagination={false} rowKey="barkodNo" bordered>
            <Table.Column align="center" title="Barkod" dataIndex="barkodNo" key="barkodNo" />
            <Table.Column align="center" title="Ürün Adı" dataIndex="urunAdi" key="urunAdi" />
            <Table.Column align="center" title="Alış Fiyat" dataIndex="alisFiyat" key="alisFiyat" render={numberFormat} />
          </Table>
        </Card>
      </div>

      <div className="max-w-[700px]">
        <Card>
          <h3 className="w-full text-center mt-0">Skor Tablosu</h3>

          <Table dataSource={tedarikciSkorModelList} pagination={false} rowKey={record => record.kullaniciModel.kullaniciId + ''} bordered>
            <Table.Column
              align="center"
              title="Kullanici"
              dataIndex={['kullaniciModel', 'kullaniciAdi']}
              sorter={compStr(['kullaniciModel', 'kullaniciAdi'])}
            />
            <Table.Column align="center" title="Bugün" dataIndex="bugunAlinan" key="bugunAlinan" render={numberFormat} sorter={compNum('bugunAlinan')} />
            <Table.Column align="center" title="Bu Hafta" dataIndex="buHaftaAlinan" key="buHaftaAlinan" render={numberFormat} sorter={compNum('buHaftaAlinan')} />
            <Table.Column align="center" title="Bu Ay" dataIndex="buAyAlinan" key="buAyAlinan" render={numberFormat} sorter={compNum('buAyAlinan')} />
          </Table>
        </Card>
      </div>

      <Modal width={'90vw'} footer={null} cancelText="Kapat" open={isModalOpen} onCancel={handleCancel} style={{ marginTop: -70 }} bodyStyle={{ marginTop: 20 }}>
        <NewOrder
          indirim={indirim}
          fillOrderTedHome={fillOrder}
          onSaveDone={() => {
            setIndirim('');
            setFillOrder(null);
            setIsModalOpen(false);
            _getList(kullaniciModel);
          }}
          lock
        />
      </Modal>
    </div>
  );
};
