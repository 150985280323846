import { PlusCircleOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import React, { Fragment, useCallback, useContext, useEffect, useMemo } from 'react';
import { z } from 'zod';
import { message } from 'antd';

import * as API from '../api';
import { UserContext } from '../contexts/UserContext';
import { useFillForm } from '../hooks';
import { ROL, SIPARIS_STATU, SiparisModel } from '../types';
import { Select } from './Select';
import { Input } from './Input';
import { InputNumber } from './InputNumber';
import { DatePicker } from './DatePicker';
import { DictFormActions } from './DictFormActions';

const OrderForm = z.object({
  kullaniciId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  krediKartiId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  pazaryeriId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  kategoriId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  markaId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  urunId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  depoyeriId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  alisFiyat: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }).gt(0, "Fiyat 0'dan büyük olmalı."),
  satisFiyat: z.number().nullable().optional(),
  siparisNo: z.string().min(1, 'Zorunludur'),
  tedarikciOdenecekTarih: z.date({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  siparisTarihi: z.date({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  tedarikciAciklama: z.string().optional(),
  seriNo: z.string().nullable().optional(),
});

export const OrderUpdate = ({ onSave = (order) => null, order, open }: { onSave: (order: SiparisModel) => void; order: SiparisModel | null; open: boolean }) => {
  const kullaniciModel = useContext(UserContext);

  const [users = [], _, getUsers] = API.USERS.useFetchTedarikci({});
  const [karts = []] = API.KART.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [markets = []] = API.MARKETS.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [categories = []] = API.CATEGORY.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [brands = []] = API.BRAND.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [products = []] = API.PRODUCT.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [warehouses = []] = API.WAREHOUSES.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });

  const { form, clear, fill } = useFillForm(OrderForm, {
    kullaniciId: kullaniciModel.kullaniciTipi === ROL.ADMIN ? undefined : kullaniciModel.kullaniciId,
    krediKartiId: undefined,
    pazaryeriId: undefined,
    markaId: undefined,
    kategoriId: undefined,
    urunId: undefined,
    depoyeriId: undefined,
    adet: 1,
    alisFiyat: undefined,
    satisFiyat: undefined,
    siparisNo: '',
    seriNo: '',
    tedarikciOdenecekTarih: new Date(),
    siparisTarihi: new Date(),
    tedarikciAciklama: '',
  });

  useEffect(() => {
    if (kullaniciModel.kullaniciTipi === ROL.ADMIN) {
      getUsers(kullaniciModel.firmaModel);
    }
  }, []);

  useEffect(() => {
    clear();
    if (order) {
      fill({
        kullaniciId: order.kullaniciModel?.kullaniciId,
        krediKartiId: order.bhrKrediKartiModel?.krediKartiId,
        pazaryeriId: order.bhrPazaryeriModel?.pazaryeriId,
        kategoriId: order.bhrKategoriModel?.kategoriId,
        markaId: order.bhrMarkaModel?.markaId,
        urunId: order.bhrUrunModel?.urunId,
        depoyeriId: order.bhrDepoyeriModel?.depoyeriId,
        alisFiyat: order.alisFiyat,
        satisFiyat: order.satisFiyat,
        siparisNo: order.siparisNo,
        seriNo: order.seriNo,
        tedarikciAciklama: order.tedarikciAciklama || '',
        tedarikciOdenecekTarih: order.tedarikciOdenecekTarih ? new Date(order.tedarikciOdenecekTarih) : new Date(),
        siparisTarihi: order.siparisTarihi ? new Date(order.siparisTarihi) : new Date(),
      });
    }
  }, [order, open]);

  const productByCategory = products.filter((product) => product.bhrKategoriModel?.kategoriId === form.value.kategoriId && product.bhrMarkaModel?.markaId === form.value.markaId);

  const kartByUser = karts?.filter((kart) => kart.kullaniciModel?.kullaniciId === form.value['kullaniciId']) || [];

  const save = useCallback(async () => {
    const { success, data } = form.parse();
    if (success) {
      const tedarikciOdenecekTarih = new Date(data.tedarikciOdenecekTarih);
      tedarikciOdenecekTarih.setHours(0);
      tedarikciOdenecekTarih.setMinutes(0);
      tedarikciOdenecekTarih.setSeconds(0);
      tedarikciOdenecekTarih.setMilliseconds(0);

      const siparisTarihi = new Date(data.siparisTarihi);
      siparisTarihi.setHours(0);
      siparisTarihi.setMinutes(0);
      siparisTarihi.setSeconds(0);
      siparisTarihi.setMilliseconds(0);
      const formedData = {
        ...order,
        bhrPazaryeriModel: {
          pazaryeriId: data.pazaryeriId,
        },
        bhrKategoriModel: {
          kategoriId: data.kategoriId,
        },
        bhrMarkaModel: {
          markaId: data.markaId,
        },
        bhrUrunModel: {
          urunId: data.urunId,
        },
        bhrDepoyeriModel: {
          depoyeriId: data.depoyeriId,
        },
        bhrKrediKartiModel : {
          krediKartiId : data.krediKartiId
        },
        seriNo: data.seriNo,
        alisFiyat: data.alisFiyat,
        satisFiyat: data.satisFiyat,
        siparisNo: data.siparisNo,
        tedarikciOdenecekTarih: tedarikciOdenecekTarih.toISOString(),
        siparisTarihi: siparisTarihi.toISOString(),
        tedarikciAciklama: data.tedarikciAciklama,
        firmaModel: kullaniciModel.firmaModel,
        kullaniciModel: {
          kullaniciId: data.kullaniciId,
        },
      };

      onSave(formedData);
      clear();
    } else {
      form.setAllTouched();
    }
  }, [form, order]);

  return (
    <div className="flex flex-col gap-2">
      <Card title="Sipariş Güncelleme">
        <div className="grid grid-cols-[100px_1fr] items-center gap-y-6 gap-x-4 sm:max-w-sm ">
          {kullaniciModel.kullaniciTipi === ROL.ADMIN && (
            <>
              <label className="whitespace-nowrap">Tedarikçi :</label>
              <Select
                options={users.map((user) => ({ ...user, label: `${user.kullaniciAdi} - ${user.ad} ${user.soyad}` }))}
                fieldNames={{ label: 'label', value: 'kullaniciId' }}
                form={form}
                name="kullaniciId"
              />
            </>
          )}

          <label className="whitespace-nowrap">Pazaryeri :</label>
          <Select options={markets} fieldNames={{ label: 'pazaryeriAdi', value: 'pazaryeriId' }} form={form} name="pazaryeriId" />

          <label className="whitespace-nowrap">Kategori :</label>
          <Select options={categories} fieldNames={{ label: 'kategoriAdi', value: 'kategoriId' }} form={form} name="kategoriId" />

          <label className="whitespace-nowrap">Marka :</label>
          <Select options={brands} fieldNames={{ label: 'markaAdi', value: 'markaId' }} form={form} name="markaId" />

          <label className="whitespace-nowrap">Ürün :</label>
          <Select
            options={productByCategory}
            fieldNames={{ label: 'urunAdi', value: 'urunId' }}
            searchFields={['barkodNo']}
            placeholder="Ürün Adı veya Barkod"
            form={form}
            name="urunId"
          />

          <label className="whitespace-nowrap">Depoyeri :</label>
          <Select options={warehouses} fieldNames={{ label: 'depoyeriAdi', value: 'depoyeriId' }} form={form} name="depoyeriId" />

          <label className="whitespace-nowrap">Kart :</label>
          <Select
            options={kartByUser}
            fieldNames={{ label: 'kartAdi', value: 'krediKartiId' }}
            searchFields={['kartAdi', 'kartNo', 'kartSahibi']}
            placeholder="Kart Adı, No veya sahibi..."
            form={form}
            name="krediKartiId"
          />

          <label className="whitespace-nowrap">Seri No :</label>
          <Input form={form} name={'seriNo'} />

          <label className="whitespace-nowrap">Alış Fiyat :</label>
          <InputNumber form={form} name="alisFiyat" min={0} />

          <label className="whitespace-nowrap">Satış Fiyat :</label>
          <InputNumber form={form} name="satisFiyat" min={0} disabled={order?.siparisStatu === SIPARIS_STATU.SIP_DEPO} />

          <label className="whitespace-nowrap">Sipariş No :</label>
          <Input form={form} name="siparisNo" />

          <label className="whitespace-nowrap">Ödenecek Tarih :</label>
          <DatePicker form={form} name="tedarikciOdenecekTarih" />

          <label className="whitespace-nowrap">Sipariş Tarih :</label>
          <DatePicker form={form} name="siparisTarihi" />

          <label className="whitespace-nowrap">Açiklama :</label>
          <Input form={form} name="tedarikciAciklama" />

          <DictFormActions save={save} clear={clear} />
        </div>
      </Card>
    </div>
  );
};
