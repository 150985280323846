import { CreditCardOutlined, LaptopOutlined } from '@ant-design/icons';
import { Card, Collapse, Table, Input as InputAnt } from 'antd';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { z } from 'zod';

import * as API from '../../api';
import { DictFormActions, DictTableActions, DictTableStatus, Input, InputNumber, Select } from '../../components';
import { UserContext } from '../../contexts/UserContext';
import { useFillForm, useWindowSize } from '../../hooks';
import { AKTIF_PASIF, KartModel, ROL, UrunModel } from '../../types';
import { moneyColumnRender, numberFormat } from '../../utils';

const KartForm = z.object({
  kartAdi: z.string().min(1, 'Zorunludur'),
  bankaAdi: z.string().min(1, 'Zorunludur'),
  kartNo: z.string().min(1, 'Zorunludur'),
  kartSahibi: z.string().min(1, 'Zorunludur'),
  kullaniciId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
});

export const Kart = () => {
  const { sm } = useWindowSize();
  const { firmaModel } = useContext(UserContext);

  const [list = [], loading, getList] = API.KART.useFetch({ init: true, initBody: firmaModel });
  const [users = []] = API.USERS.useFetch({ init: true, initBody: firmaModel });
  const active = API.KART.useFetchActive({});
  const { form, selected, clear, fill } = useFillForm(KartForm, { kartAdi: '', bankaAdi: '', kartNo: '', kartSahibi: '', kullaniciId: undefined });

  const [search, setSearch] = useState<string>('');

  const save = useCallback(async () => {
    const { success, data } = form.parse();

    if (success) {
      const formedData: KartModel = {
        bankaAdi: data.bankaAdi,
        kartAdi: data.kartAdi,
        kartNo: data.kartNo,
        kartSahibi: data.kartSahibi,
        kullaniciModel: {
          kullaniciId: data.kullaniciId,
        },
        firmaModel: firmaModel,
      };
      let result: any = null;
      if (selected) {
        result = await API.KART.update({
          ...formedData,
          aktifPasif: selected.aktifPasif,
          krediKartiId: selected.krediKartiId,
        });
      } else {
        result = await API.KART.save({
          ...formedData,
          aktifPasif: AKTIF_PASIF.AKTIF,
        });
      }

      if (result?.ok) {
        clear();
        getList(firmaModel);
        active[2](firmaModel);
      }
    } else {
      form.setAllTouched();
    }
  }, [form, selected]);

  const remove = useCallback(async (values) => {
    const result = await API.KART.activePassive({
      krediKartiId: values.krediKartiId,
      aktifPasif: values.aktifPasif === AKTIF_PASIF.AKTIF ? AKTIF_PASIF.PASIF : AKTIF_PASIF.AKTIF,
    });

    if (result.ok) {
      clear();
      getList(firmaModel);
    }
  }, []);

  const fillForm = useCallback(
    (values) => {
      fill({
        krediKartiId: values.krediKartiId,
        aktifPasif: values.aktifPasif,
        kartAdi: values.kartAdi,
        bankaAdi: values.bankaAdi,
        kartNo: values.kartNo,
        kartSahibi: values.kartSahibi,
        kullaniciId: values.kullaniciModel.kullaniciId,
      });
    },
    [fill]
  );

  const filteredList = useMemo(() => {
    return list?.filter(
      (item) =>
        item.kartAdi?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
        item.bankaAdi?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
        item.kartSahibi?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
        item.kartNo?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
        item.kullaniciModel?.kullaniciAdi?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) 
    ) || [];
  }, [list, search]);

  const columns = [
    {
      title: 'Kart Adı',
      dataIndex: 'kartAdi',
      key: 'kartAdi',
    },
    {
      title: 'Kullanici',
      dataIndex: ['kullaniciModel', 'kullaniciAdi'],
      key: 'kullaniciAdi',
    },
    {
      title: 'Banka Adı',
      dataIndex: 'bankaAdi',
      key: 'bankaAdi',
    },
    {
      title: 'Kart No',
      dataIndex: 'kartNo',
      key: 'kartNo',
    },
    {
      title: 'Kart Sahibi',
      dataIndex: 'kartSahibi',
      key: 'kartSahibi',
    },
    {
      title: 'Durum',
      dataIndex: 'aktifPasif',
      key: 'aktifPasif',
      width: sm ? 100 : 70,
      render: (_: any, record: UrunModel) => <DictTableStatus record={record} />,
    },
    {
      title: 'İşlemler',
      key: 'actions',
      width: sm ? 240 : 120,
      render: (_: any, record: UrunModel) => <DictTableActions record={record} remove={remove} fill={fillForm} />,
    },
  ];

  return (
    <div className="flex flex-col gap-2">
      <Collapse accordion defaultActiveKey="1">
        <Collapse.Panel header={<span className="text-base font-semibold ">Yeni Kart Tanımlama</span>} key="1">
          <div className="grid grid-cols-[100px_1fr] items-center gap-y-6 gap-x-4  sm:max-w-3xl sm:grid sm:grid-cols-[100px_1fr_100px_1fr] sm:grid-flow-row">
            <label className="whitespace-nowrap">Kart Adı :</label>
            <Input form={form} name="kartAdi" />

            <label className="whitespace-nowrap">Kullanıcı :</label>
            <Select options={users} fieldNames={{ label: 'kullaniciAdi', value: 'kullaniciId' }} form={form} name="kullaniciId" />

         

            <label className="whitespace-nowrap">Banka Adı :</label>
            <Input form={form} name="bankaAdi" />

            <label className="whitespace-nowrap">Kart No :</label>
            <Input form={form} name="kartNo" />

            <label className="whitespace-nowrap">Kart Sahibi :</label>
            <Input form={form} name="kartSahibi"/>

            <span></span>

            <DictFormActions selected={selected} save={save} clear={clear} className="sm:col-span-4 sm:justify-end" />
          </div>
        </Collapse.Panel>
      </Collapse>

      <div className="flex items-center gap-2 ml-4">
        <label>Ara :</label>
        <InputAnt.Search className="w-64" placeholder="Kart Adı, No veya Sahibi..." allowClear onChange={(e) => setSearch(e.target.value)} />
      </div>

      <Table
        scroll={{ y: sm ? 'calc(100svh - 240px)' : 'calc(100svh - 190px)' }}
        pagination={false}
        rowKey="krediKartiId"
        size="small"
        bordered
        showSorterTooltip={false}
        columns={columns}
        dataSource={filteredList}
        loading={loading}
      />
    </div>
  );
};

Kart.path = 'kart';
Kart.title = 'Kart';
Kart.group = 'dicts';
Kart.roles = [ROL.ADMIN];
Kart.icon = <CreditCardOutlined />;
